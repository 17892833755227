import React from "react";
import {ModalContext} from "../Page";
import DataProvider, {siteApiHost} from "../DataProvider";

export default class OrderBookingProvider extends React.Component {
    constructor(props) {
        super(props);
        OrderBookingProvider.contextType = ModalContext;
    }

    render() {
        return (<DataProvider
                loadingText={<h1 className={"empty-result"}>Бронируем для
                    вас&hellip;</h1>}
                body={{
                    card_id: this.props.card.id
                }}
                method="POST"
                authToken={true}
                onDataLoaded={this.onBookingComplete}
                url={`${siteApiHost}orders`}/>
        )
    }
}