import _ from "underscore";

export const currency_dict =  {
    "eur": " €",
    "rub": " ₽",
    "usd": " $"
};

export const currency_dict_to_select = _.map(currency_dict, function (a,b) {
    return {value: b, label: a};
});

export function startedOrder() {
    return localStorage.getItem("startedOrder");
}