import React, {Fragment} from "react";
import Logic from "./Logic";

const Form = {};

Form.Control = class extends React.Component {
    isValid = () => {
        const {form, name} = this.props;
        return form.hasOwnProperty(name) && form[name].length > 0;
    };

    render() {
        return (
            <div className={"form-control"}>
                <input type={this.props.type} name={this.props.name}
                       onChange={this.props.onChange}
                       value={this.props.value}
                       placeholder={this.props.placeholder}
                       required={this.props.required}/>

                <Logic.Unless item={this.isValid}>
                    <div className={"form-error"}>{this.props.error}</div>
                </Logic.Unless>
            </div>
        )
    }
};

Form.CheckboxControl = class extends React.Component {
    render() {
        return (
            <div className="form-control form-checkbox form-policy">
                <label className="control-checkbox">
                    <input onChange={this.setPayDisabled} type="checkbox"
                           name={this.props.name}/>
                    <i></i> {this.props.label}
                </label>
            </div>
        )
    }
}

Form.RadioControl = class extends React.Component {
    isValid = () => {
        console.log("valid", this.props);
        const {form, name} = this.props;
        return form.hasOwnProperty(name) && form[name].length > 0;
    };

    render() {
        return (
            <Fragment>
                <div className="form-control form-checkbox">
                    {this.props.options.map(i => (
                        <label className="control-checkbox"><input value={i[0]}
                                                                   onChange={this.props.onChange}
                                                                   type="radio"
                                                                   name="type"
                        /><i></i> {i[1]}</label>
                    ))}
                </div>

                <Logic.Unless item={this.isValid}>
                    <div className="form-control form-checkbox">
                        <div className={"form-error"}>{this.props.error}</div>
                    </div>
                </Logic.Unless>
            </Fragment>

        )
    }
};

Form.EmailControl = class extends React.Component {
    render() {
        return <Form.Control placeholder={"Ваш email"} type={"email"}
                             name={"email"}
                             required={true}
                             error={"Укажите email"} {...this.props} />
    }
};
Form.PasswordControl = class extends React.Component {
    render() {
        return <Form.Control placeholder={"Пароль"} type={"password"}
                             name={"password"}
                             required={true}
                             error={"Неверный пароль"} {...this.props} />
    }
};

Form.Submit = class extends React.Component {
    state = {
        submitting: false,
    };

    onSubmit = e => {
        e.preventDefault();
        this.setState({submitting: true});
    };

    onSubmitted = () => {
        console.log("submitted");
        this.setState({submitting: false});
    };

    render() {
        const className = this.props.className || "form-submit";

        return (
            <Fragment>
                <div className={className}>
                    <button type="submit"
                            onClick={this.onSubmit}>{this.props.label}</button>

                    <Logic.If item={this.state.submitting}>
                        {React.cloneElement(this.props.provider, Object.assign({}, this.props, {
                            onSubmitted: this.onSubmitted,
                            init: false,
                        }))}
                    </Logic.If>
                </div>
            </Fragment>
        );
    }
};

Form.Form = class extends React.Component {
    state = {
        form: {},
        errors: [],
        valid: false,
    };

    onChange = e => {
        const form = this.state.form;
        form[e.target.name] = e.target.value;
        this.setState({form: form});
    };

    render() {
        return (
            <Fragment>
                <form className={"form"}>
                    {this.props.children.map(item => (
                        React.cloneElement(item, {
                            onChange: this.onChange,
                            form: this.state.form,
                        })
                    ))}
                </form>
            </Fragment>
        )
    }
};

export default Form;