import React, {Fragment} from "react";
import FlightsBlockSm from "./flightsBlock/FlightsBlockSm";
import FlightsBlockMd from "./flightsBlock/FlightsBlockMd";
import FlightsBlockLg from "./flightsBlock/FlightsBlockLg";
import Media from "react-media";
import SortBlock from "./SortBlock";

export default class FlightsBlock extends React.Component {

    render() {
        return (
            <Fragment>
                <SortBlock onSort={this.props.onSort} />

                <Media queries={{
                    small: "(max-width: 599px)",
                    medium: "(min-width: 600px) and (max-width: 1199px)",
                    large: "(min-width: 1200px)"
                }}>
                    {matches => (
                        <Fragment>
                            {matches.small && <FlightsBlockSm  {...this.props} />}
                            {matches.medium && <FlightsBlockMd {...this.props} />}
                            {matches.large && <FlightsBlockLg {...this.props} />}
                        </Fragment>
                    )}
                </Media>
            </Fragment>
        )
    }
}