import React, {Fragment} from "react";
import moment from "moment";
import _ from "underscore";
import airports from "../airports";
import {currency_dict} from "./Helpers";
import NumberFormat from "react-number-format";
import Logic from "./Logic";

export default class RouteDecorator extends React.Component {
    flight_duration = () => {
        const d1 = moment(this.props.route.departure_date);
        const d2 = moment(this.props.route.arrival_date);

        const hourses = d2.diff(d1, 'hours');
        const minutes = (d2.diff(d1, 'minutes') % 60);

        return (
            <Fragment>
                <Logic.If item={hourses > 0}>{hourses}<small>ч</small>&nbsp;</Logic.If>
                {minutes}<small>мин</small>
            </Fragment>
        )
    };

    max_pax_with_text = () => {
        return `${this.props.route.max_pax} мест`;
    };

    max_pax_without_text = () => {
        return this.props.route.max_pax;
    };

    pax_without_text = () => {
        return this.props.route.pax;
    };

    pax_with_text = () => {
        return `${this.props.route.pax} мест`;
    };

    aircraft_year_of_creation = () => {
        return this.props.route.aircraft_year_of_creation;
    }

    aircraft_year_of_overhaul = () => {
        return this.props.route.aircraft_year_of_overhaul;
    }

    aircraft_year_of_renewal = () => {
        return this.props.route.aircraft_year_of_renewal;
    }

    departure_airport = () => {
        return _.findWhere(airports(), {"icao": this.props.route.departure_airport});
    };

    arrival_airport = () => {
        return _.findWhere(airports(), {"icao": this.props.route.arrival_airport});
    };

    time = (d) => {
        return moment(d).format("HH:mm");
    };

    date = (d) => {
        return moment(d).format("DD MMMM");
    };

    arrival_time = () => {
        return this.time(this.props.route.arrival_date);
    };

    arrival_date = () => {
        return this.date(this.props.route.arrival_date);
    };

    departure_date = () => {
        return this.date(this.props.route.departure_date);
    };

    departure_time = () => {
        return this.time(this.props.route.departure_date);
    };

    price = () => {
        const curr = this.props.currency || "rub";
        const suffix = currency_dict[curr];

        return <NumberFormat value={this.props.route.price[this.props.currency || "rub"]} displayType={'text'}
                             thousandSeparator={" "} suffix={suffix}/>
    };

    operatorLogo = () => {

        var operator = "";

        if (this.props.hasOwnProperty("order")) {
            operator = this.props.order.operator;
        } else if (this.props.hasOwnProperty("route")) {
            operator = this.props.route.operator;
        }

        return <img src={`/config/${operator}/public/static/img/logo.png`} alt=""/>
    };

    aircraft = () => {
        return this.props.route.aircraft
    };

    aircraftType = () => {
        return this.props.route.aircraft_type
    };

    orderType = () => {
        switch (this.props.orders_type) {
            case "booked":
                return "Зарезевирован";
            case "paid":
                return "Оплачен";
            case "cancelled_booked":
                return "Бронь отменена";
            case "cancelled_paid":
                return "Оплата отменена";
            case "completed":
                return "Завершен";
            default:
                return "Хз"
        }
    };

    render() {
        return (
            <Fragment>
                {[this.props.children].map(item => (
                    React.cloneElement(item,
                        {
                            order: this.props.order,
                            route: this.props.route,
                            price: this.price,
                            departure_time: this.departure_time,
                            departure_date: this.departure_date,
                            arrival_date: this.arrival_date,
                            arrival_time: this.arrival_time,
                            arrival_airport: this.arrival_airport,
                            aircraft_type: this.aircraftType,
                            aircraft: this.aircraft,
                            departure_airport: this.departure_airport,
                            max_pax_without_text: this.max_pax_without_text,
                            max_pax_with_text: this.max_pax_with_text,
                            flight_duration: this.flight_duration,
                            operatorLogo: this.operatorLogo,
                            orderType: this.orderType,
                            aircraft_year_of_creation: this.aircraft_year_of_creation,
                            aircraft_year_of_overhaul: this.aircraft_year_of_overhaul,
                            aircraft_year_of_renewal: this.aircraft_year_of_renewal,
                            pax_without_text: this.pax_without_text,
                            pax_with_text: this.pax_with_text,
                        }
                    )
                ))}
            </Fragment>
        )
    }
}