import React from "react";
import Form from "../Form";
import {Modal} from "../Modals";
import {RegProvider} from "../providers/RegProvider";

export default class ModalReg extends React.Component {
    render() {
        return (
            <Modal title={"Регистрация"} type={"reg"} {...this.props}>
                <Form.Form {...this.props}>
                    <Form.Control type={"text"} name={"name"}
                                  placeholder={"Ваше имя"} required={true}
                                  error={"Введите имя"}/>

                    <Form.Control type={"tel"} name={"phone"}
                                  placeholder={"Телефон +7..."}
                                  required={true}
                                  error={"Не корректный телефон"}/>

                    <Form.EmailControl/>

                    <Form.RadioControl name={"type"}
                                       options={[["physical", "Частное лицо"], ["legal", "Компания"]]}
                                       error={"Нужно выбрать"}/>

                    <div className="form-control form-require">
                        Все поля обязательны для заполнения
                    </div>

                    <div className="form-control form-checkbox form-policy">
                        <label className="control-checkbox"><input
                            onClick={this.setSubmitDisabled}
                            type="checkbox" name="policy"/><i></i>
                            <span>Я ознакомился и согласен с <a>Правилами пользования сервисом</a> и с <a
                                href="#">политикой конфиденциальности в отношении персональных данных</a></span></label>
                    </div>

                    <Form.Submit label={"Зарегистрироваться"}
                                 provider={<RegProvider/>}/>
                </Form.Form>
            </Modal>
        );
    }
}
