import React, {Fragment} from "react";
import FlightsBlock from "./FlightsBlock";
import {stringify} from "query-string";
import _ from "underscore";
import moment from "moment";
import Logic from "../../../components/Logic";
import DataProvider, {coreApiHost} from "../../../components/DataProvider";
import ShareBlock from "../../../components/ShareBlock";
import {Redirect} from "react-router-dom";

export default class FlightsProvider extends React.Component {
    state = {
        items: [],
        searchId: null,
        sort: "time",
    };

    onSort = (e) => {
        let items = this.state.items;

        switch (e) {
            case "time":

                items = _.sortBy(this.state.items, function (item) {
                    const route = _.first(item.routes);

                    const d1 = route.departure_date;
                    const d2 = route.arrival_date;

                    return moment(d2).diff(d1, 'minutes');
                });

                break;
            case "price":
            default:
                items = _.sortBy(this.state.items, function (item) {
                    return item.routes.filter(route => route).reduce(function (a, b) {
                        return a + b.price.rub
                    }, 0);
                });
                break;
            case "pax":

                items = _.sortBy(this.state.items, function (item) {
                    return _.first(item.routes).max_pax;
                }).reverse();

                break;
        }

        this.setState({items: items});
    };

    onDataLoaded = (result) => {
        this.setState({
            isLoaded: true,
            items: result.cards,
            searchId: result.id
        });
    };

    getSid = () => {
        try {
            return this.props.match.params.sid;
        } catch (e) {
            return null;
        }
    };

    render() {
        const {items, searchId} = this.state;
        const {query} = this.props;

        const url = `${coreApiHost}cards`;
        const url1 = `${url}?${stringify(query)}`;
        const to = `/search/${searchId}`;
        const url2 = `${coreApiHost}requests/${this.getSid()}`;
        const loadingText1 = "Идет поиск рейсов";

        return (
            <Fragment>
                <Logic.If item={query}>
                    <DataProvider url={url1} loadingText={loadingText1}
                                  onDataLoaded={this.onDataLoaded}>
                        <Logic.If item={searchId}>
                            <Redirect push to={to}/>
                        </Logic.If>
                    </DataProvider>
                </Logic.If>

                <Logic.If item={this.getSid}>
                    <DataProvider url={url2} loadingText={loadingText1}
                                  onDataLoaded={this.onDataLoaded}/>
                </Logic.If>

                <Logic.If item={items}>
                    <FlightsBlock onSort={this.onSort} searchId={searchId}
                                  items={items}/>
                    <ShareBlock/>
                </Logic.If>
            </Fragment>
        );
    }
}