import React from "react";

export default function AttentionBlock() {
    return (
        <div className="attention-block">
            Внимание! Приведенные варианты перелета являются актуальными на 20.11.2019-18:00. <br/>
            Вы можете поделиться всей таблицей с вариантами, при этом одно или несколько предложений может потерять
            актуальность, поскольку не забронированы.<br/>
            Для согласования и оплаты конкретного предложения, нажмите кнопку «Выбрать рейс», после чего он
            забронируется на 30 минут.<br/>
            Предложены варианты -12 + 24 часа от желаемого времени вылета.<br/>
            Показаны все самолеты с количествои мест больше или равном указанному количеству пассажиров.<br/>
            Стоимость перелета зависит от типа самолета и Empty leg.
        </div>
    )
}