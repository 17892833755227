import React from "react";

export default function TopBlock() {
    return (
        <div className="top-block">
            <div className="slogan">
                <div className="slogan-title">Первый в&nbsp;России сервис <i><span>онлайн</span></i> бронирования
                    и&nbsp;продаж рейсов деловой авиации
                </div>
                <a className="slogan-more" href="#">Узнать больше о сервисе</a>
            </div>
            <div className="steps">
                <div className="step">
                    <div className="step-img"><a href="img/step1-big.jpg"><img src="img/step1.png" alt="" /></a></div>
                    <div className="step-text">
                        <div className="step-num">1</div>
                        Введите данные и&nbsp;вы<br/> немедленно получите<br/> реальные предложения
                    </div>
                </div>
                <div className="step">
                    <div className="step-img"><a href="img/step2-big.jpg"><img src="img/step2.png" alt="" /></a></div>
                    <div className="step-text">
                        <div className="step-num">2</div>
                        Выберите вариант<br/> из предложенных
                    </div>
                </div>
                <div className="step">
                    <div className="step-img"><a href="img/step1-big.jpg"><img src="img/step3.png" alt="" /></a></div>
                    <div className="step-text">
                        <div className="step-num">3</div>
                        Оплатите
                    </div>
                </div>
                <div className="step">
                    <div className="step-img"><a href="img/step1-big.jpg"><img src="img/step4.png" alt="" /></a></div>
                    <div className="step-text">
                        <div className="step-num">4</div>
                        Летите
                    </div>
                </div>
            </div>
        </div>
)
}