import React from "react";
import OrderOrderBlockForm from "./OrderOrderBlockForm";
import {Link} from "react-router-dom";

export default class OrderOrderBlock extends React.Component {
    render() {
        return (
            <div className="order-block">
                <div className="rules">
                    <div className="rules-company">
                        <div className="rules-h2">Правила и условия авиакомпании
                            Вельталь-авиа
                        </div>
                        <div className="rules-link"><a href="#">Договор о
                            выполнении воздушного чартера</a></div>
                        <div className="rules-link"><a href="#">Правила
                            воздушных перевозок пассажиров и багажа</a>
                        </div>
                    </div>
                    <div className="rules-company">
                        <div className="rules-h2">Правила и условия авиакомпании
                            Люкслаб
                        </div>
                        <div className="rules-link"><a href="#">Договор о
                            выполнении воздушного чартера</a></div>
                        <div className="rules-link"><a href="#">Правила
                            воздушных перевозок пассажиров и багажа</a>
                        </div>
                    </div>
                </div>

                <OrderOrderBlockForm {...this.props} />

                <div className="continue">
                    <Link
                        to={`/search/${this.props.match.params.sid}`}><i></i> Вернуться
                        к результатам поиска</Link>
                    <Link to={"/"}>Новый поиск <i></i></Link>
                </div>
            </div>
        )
    }
}