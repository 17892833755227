import React from "react";
import {Redirect} from "react-router-dom";
import moment from "moment";
import 'moment/locale/ru';
import RouteDecorator from "../../../../components/RouteDecorator";

moment.locale("ru");

class OrdersBlockLgRowTr extends React.Component {
    state = {
        redirect: false
    };

    handleOnClick = () => {
        this.setState({redirect: true});
    };

    render() {
        const {
            route,
            order,
            aircraft_type,
            aircraft,
            operatorLogo,
            departure_airport,
            departure_date,
            departure_time,
            arrival_airport,
            arrival_date,
            arrival_time,
            orderType,
        } = this.props;

        if (this.state.redirect) {
            const to = `/orders/${order.order_id}`;
            return <Redirect push to={to}/>
        }

        return (
            <tr onClick={this.handleOnClick}>
                <td className="order-num"><b>{order.order_num}</b></td>
                <td className="order-plane"><b>{aircraft_type()}</b><br/><small>{aircraft()}</small></td>

                <td className="order-logo">{operatorLogo()}
                </td>
                <td className="order-airport">
                    <div>{departure_airport().name}<br/><small>{departure_airport().city}</small>
                    </div>
                </td>
                <td className="order-date">{departure_date()}<br/><small>{departure_time()}</small>
                </td>

                <td className="order-airport">
                    <div>{arrival_airport().name}<br/><small>{arrival_airport().city}</small></div>
                </td>

                <td className="order-date">{arrival_date()}<br/><small>{arrival_time()}</small></td>

                <td className="order-status">{orderType()}</td>
            </tr>
        )
    }
}

export default class OrdersBlockLgRow extends React.Component {
    render() {
        return (
            <RouteDecorator {...this.props}>
                <OrdersBlockLgRowTr/>
            </RouteDecorator>
        )
    }
}