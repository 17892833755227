import React, {Fragment} from "react";

export default class FLightsBlockSm extends React.Component {
    flightTitle = (index) => {
        return index === 0 ? "Туда" : "Обратно"
    };
    
    render() {
        const {items} = this.props;

        return (
            <div className="flights-block flights-block-sm">
                {items.map(item => (
                    <Fragment>

                        {item.routes.filter(route => route).map((route, index) => (
                            <div className="flight-item">
                                <div className="flight-title">{this.flightTitle(index)}}</div>
                                <table>
                                    <tr>
                                        <th className="flight-plane">Воздушное судно</th>
                                        <th className="flight-logo">Авиакомпания</th>
                                    </tr>
                                    <tr>
                                        <td className="flight-plane">
                                            <div className="flight-plane-info">
                                                <div className="flight-plane-name"><b>Hawker
                                                    750</b><br/><small>N277RS</small>
                                                </div>
                                                <div className="flight-plane-img"><img src="img/planes/5.jpg"
                                                                                       alt=""/><small>8
                                                    мест</small></div>
                                            </div>
                                        </td>
                                        <td className="flight-logo"><img src="img/weltall/logo.png" alt=""/></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <th className="flight-airport">Аэропорт вылета</th>
                                        <th className="flight-airport">Аэропорт прилета</th>
                                    </tr>
                                    <tr>
                                        <td className="flight-airport">
                                            <div>Шереметьево<br/><small>Москва</small></div>
                                        </td>
                                        <td className="flight-airport">
                                            <div>Ааппилатток-Нанорталик<br/><small>Ааппилатток-Нанорталик</small></div>
                                        </td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <th className="flight-date">Дата и время вылета</th>
                                        <th className="flight-date">Дата и время прилета</th>
                                    </tr>
                                    <tr>
                                        <td className="flight-date">18.09<br/><small>12:00</small></td>
                                        <td className="flight-date">18.09<br/><small>14:20</small></td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <th className="flight-duration">В пути</th>
                                        <th className="flight-price">
                                            Цена
                                            <div className="control">
                                                <button className="button-dropdown"><i data-currency="rub"></i></button>
                                                <div className="dropdown">
                                                    <div className="dropdown-item"><i data-currency="rub"></i></div>
                                                    <div className="dropdown-item"><i data-currency="usd"></i></div>
                                                    <div className="dropdown-item"><i data-currency="eur"></i></div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="flight-duration">2<small>ч</small> 20<small>мин</small></td>
                                        <td className="flight-price">10 000 000</td>
                                    </tr>
                                </table>
                            </div>
                        ))}

                        <div className="flight-summary">
                            <div>Итого: 20 000 000</div>
                            <button className="submit">Выбрать рейс</button>
                        </div>
                    </Fragment>
                ))}
            </div>
        )
    }
}