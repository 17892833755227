import React, {Fragment} from "react";
import DataProvider from "../DataProvider";
import {stringify} from "query-string";
import {accessToken} from "../Auth";
import airports from "../../airports";
import _ from "underscore";
import QRCode from "qrcode.react";
import {CopyToClipboard} from "react-copy-to-clipboard";

class PayLinkLoader extends React.Component {
    render() {
        const {order} = this.props;

        const departure_airport = _.findWhere(airports(), {"icao": order.departure_airport});
        const arrival_airport = _.findWhere(airports(), {"icao": order.arrival_airport});

        const queryParams = {
            order_id: order.order_id,
            operator_ru: order.operator,
            departure_airport_name: departure_airport.name,
            departure_city_name: departure_airport.city,
            arrival_airport_name: arrival_airport.name,
            arrival_city_name: arrival_airport.city,
        };

        var url = `http://srv-dev.najet.ru:7011/api/pay/get-payment-page?${stringify(queryParams)}`;
        url = "http://www.mocky.io/v2/5e768a822f0000e6579860c9";

        return (
            <DataProvider loadingText={"Переводим вас на страницу оплаты"} authToken={accessToken()}
                          onDataLoaded={this.props.onLinkLoaded}
                          url={url}>
                <Fragment/>
            </DataProvider>
        )
    }
}

class PayLinkSender extends React.Component {
    render() {
        const {order, payType} = this.props;

        const departure_airport = _.findWhere(airports(), {"icao": order.departure_airport});
        const arrival_airport = _.findWhere(airports(), {"icao": order.arrival_airport});

        const body = {
            order_id: order.order_id,
            operator_ru: order.operator,
            departure_airport_name: departure_airport.name,
            departure_city_name: departure_airport.city,
            arrival_airport_name: arrival_airport.name,
            arrival_city_name: arrival_airport.city,
        };

        switch (payType) {
            case 'to_email':
                var url = 'http://srv-dev.najet.ru:7011/api/pay/email-non-cash';
                break;
            case 'cachless':
                var url = 'http://srv-dev.najet.ru:7011/api/pay/email-payment-page';
                break;
        }

        var url = "http://www.mocky.io/v2/5e768a822f0000e6579860c9";

        return (
            <DataProvider body={body} method={"POST"} loadingText={"Отправляем ссылку для вас"} authToken={accessToken()}
                          onDataLoaded={this.props.onLinkSent}
                          url={url}>
                <Fragment/>
            </DataProvider>
        )
    }
}

class PaymentCard extends React.Component {
    render() {
        return (
            <div onClick={this.props.onClick} className="payments-item">
                <img src="/img/payment/payment-card.png" alt=""/>
                <b>Оплатить картой онлайн</b>
                <small>Время бронирования увеличится на 30 минут</small>
            </div>
        )
    }
}

class PaymentQR extends React.Component {
    state = {
        qrOpened: false,
    };

    openQR = () => {
        this.setState({qrOpened: !this.state.qrOpened});
    };

    render() {
        return (
            <Fragment>
                <div onClick={this.openQR} className="payments-item">
                    <img src="/img/payment/payment-qr.png" alt=""/>
                    <b>Получить QR-код <a href="#">Отобразить QR-код</a></b>
                    <small>Время бронирования увеличится на 30 минут</small>

                    {this.state.qrOpened ? <QRCode value={this.props.link}/> : <Fragment/>}
                </div>
            </Fragment>
        )
    }
}

class PaymentCopyLink extends React.Component {
    state = {
        copied: false,
    };

    copy = () => this.setState({copied: true});

    render() {
        return (
            <Fragment>
                <div onClick={this.props.onClick} className="payments-item payments-item-link">
                    <img src="/img/payment/payment-link.png" alt=""/>
                    <b>Получить ссылку для оплаты</b>
                    <small>Время бронирования увеличится на 30 минут</small>

                    {this.props.link ?
                        <b><input type="text" readOnly value={this.props.link}/> {this.state.copied ? " ✔ " : ""}
                            <CopyToClipboard text={this.props.link} onCopy={this.copy}>
                                <a>Скопировать ссылку</a>
                            </CopyToClipboard>
                        </b> : <Fragment/>}
                </div>
            </Fragment>
        )
    }
}

class PaymentSendToEmail extends React.Component {
    render() {
        if (this.props.cashless) {
            return (
                <div onClick={this.props.onClick} className="payments-item">
                    <img src="/img/payment/payment-invoice.png" alt=""/>
                    <b>Сформировать счет для оплаты по безналичному расчету</b>
                    <small>Время бронирования увеличится на 3 суток</small>
                </div>
            )
        } else {
            return (
                <div onClick={this.props.onClick} className="payments-item">
                    <img src="/img/payment/payment-mail.png" alt=""/>
                    <b>Отправить счет для оплаты онлайн на почту</b>
                    <small>Время бронирования увеличится на 30 минут</small>
                </div>
            );
        }
    }
}

export default class ModalPay extends React.Component {
    state = {
        needLoadLink: false,
        needSendLink: false,
        payLink: "",
        payType: "",
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.payLink && this.state.payType === "card") {
            window.open(this.state.payLink, "_blank");
        }
    }

    onLinkSent = () => {
        this.setState({needSendLink: false});
    };

    setLink = (link) => {
        this.setState({payLink: link.link});
    };

    payByCard = () => {
        this.setState({payType: "card", needLoadLink: true, needSendLink: false});
    };

    payByQR = () => {
        this.setState({payType: "qr", needLoadLink: true,  needSendLink: false});
    };

    payByLink = () => {
        this.setState({payType: "link", needLoadLink: true,  needSendLink: false});
    };

    sendBillToEmail = () => {
        this.setState({payType: "to_email", needLoadLink: false, needSendLink: true});
    };

    sendCashlessBillToEmail = () => {
        this.setState({payType: "cashless", needLoadLink: false, needSendLink: true});
    };

    render() {
        const {order} = this.props;
        console.log(this.props);

        return (
            <Fragment>
                {this.state.needLoadLink && !this.state.payLink.length ?
                    <PayLinkLoader order={order} onLinkLoaded={this.setLink}/> : <Fragment/>}

                {this.state.needSendLink ? <PayLinkSender onLinkSent={this.onLinkSent} order={order} payType={this.state.payType}/> : <Fragment/>}

                <div className="modal modal-active" id="modal-pay">
                    <div className="modal-window">
                        <div className="modal-close" onClick={this.props.onModalClose} title="Закрыть"></div>
                        <div className="modal-body">
                            <div className="modal-title">Выберите удобный способ оплаты:</div>
                            <form className="form" noValidate>
                                <div className="payments">
                                    <PaymentCard onClick={this.payByCard} link={this.state.payLink}/>
                                    <PaymentQR link={this.state.payLink} order={order}/>
                                    <PaymentCopyLink onClick={this.payByLink} link={this.state.payLink}/>
                                    <PaymentSendToEmail  onClick={this.sendBillToEmail} cashless={false}/>
                                    <PaymentSendToEmail  onClick={this.sendCashlessBillToEmail} cashless={true}/>
                                </div>

                                <div className="form-bottom">
                                    Для оформления контрактных форм оплаты и/или изменения времени<br/>
                                    бронирования свяжитесь с авиакомпанией.<br/>
                                    Номер вашего заказа: {this.props.order.order_id}<br/>
                                    Тел.: <a href="tel:+73812927717">+7 (3812) 92-77-17</a><br/>
                                    Email: <a href="mailto:info@weltal.ru">info@weltal.ru</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}