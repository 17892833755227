import React from "react";
import {Redirect} from "react-router-dom";
import moment from "moment";
import 'moment/locale/ru';
import RouteDecorator from "../../../../components/RouteDecorator";

moment.locale("ru");


class FlightBlockLgRowTr extends React.Component {
    state = {
        redirect: false
    };

    handleOnClick = () => {
        this.setState({redirect: true});
    };

    render() {
        const {
            route,
            order,
            aircraft_type,
            aircraft,
            operatorLogo,
            departure_airport,
            departure_date,
            departure_time,
            arrival_airport,
            arrival_date,
            arrival_time,
            orderType,
            max_pax_with_text,
            price,
            flight_duration,
        } = this.props;

        if (this.state.redirect) {
            const to = `/search/${this.props.searchId}/cards/${this.props.cardId}`;
            return <Redirect push to={to}/>
        }

        return (
            <tr onClick={this.handleOnClick}>
                <td className="flight-plane">
                    <div className="flight-plane-info">
                        <div className="flight-plane-name">
                            <b>{aircraft_type()}</b><br/><small>{aircraft()}</small>
                        </div>

                        <div className="flight-plane-img"><img
                            src={`https://media.najet.ru${route.aircraft_picture_url}`}
                            alt=""/><small>{max_pax_with_text()}</small></div>
                    </div>
                </td>
                <td className="flight-logo">
                    {operatorLogo()}
                </td>
                <td className="flight-airport">
                    <div>{departure_airport().name}<br/><small>{departure_airport().city}</small></div>
                </td>
                <td className="flight-date">{departure_date()}<br/><small>{departure_time()}</small></td>
                <td className="flight-airport">
                    <div>{arrival_airport().name}<br/><small>{arrival_airport().city}</small>
                    </div>
                </td>
                <td className="flight-date">{arrival_date()}<br/><small>{arrival_time()}</small></td>
                <td className="flight-duration">{flight_duration()}
                </td>
                <td className="flight-price">{price()}</td>
            </tr>
        )
    }
}

export default class FlightBlockLgRow extends React.Component {
    render() {
        return (
            <RouteDecorator {...this.props}>
                <FlightBlockLgRowTr {...this.props}/>
            </RouteDecorator>
        );
    }

}