import React from "react";

export default class SortBlock extends React.Component {
    state = {
        items: [
            { "value": "time", "label": "Времени"},
            { "value": "price", "label": "Цене"},
            { "value": "pax", "label": "Количеству мест"},
        ]
    };

    onSort = (e) => {
        e.target.classes += "control-button-active";
        this.props.onSort(e.target.getAttribute('data-name'));
    };

    render()  {
        return (
            <div className="sort-block">
                <div className="title">Сортировать по:</div>
                <div className="controls">
                    {this.state.items.map((item) => (
                        <button data-name={item.value}  onClick={this.onSort} className="control-button">{item.label}</button>
                    ))}
                </div>
            </div>
        )
    }
}