import React from "react";
import {Modal} from "../Modals";
import {ModalContext} from "../Page";

export default class ModalConfirm extends React.Component {
    constructor(props) {
        super(props);
        ModalConfirm.contextType = ModalContext
    }

    render() {
        return (
            <Modal title={<span>Мы отправили письмо<br/> на указанный email</span>} type={"confirm"} {...this.props}>
                Пройдите по ссылке из письма для продолжения регистрации.
            </Modal>
        )
    }
}