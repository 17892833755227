export default function airports() {
    return [
        {
            "icao": "UUWW",
            "iata": "VKO",
            "name": "Внуково",
            "nameEn": "Vnukovo",
            "city": "Москва",
            "cityEn": "MOSCOW",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "ULLI",
            "iata": "LED",
            "name": "Пулково",
            "nameEn": "PULKOVO",
            "city": "Санкт-Петербург",
            "cityEn": "ST PETERSBURG",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "URSS",
            "iata": "AER",
            "name": "Адлер",
            "nameEn": "ADLER",
            "city": "Сочи",
            "cityEn": "SOCHI",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "UWKD",
            "iata": "KZN",
            "name": "Казань",
            "nameEn": "KAZAN",
            "city": "Казань",
            "cityEn": "KAZAN",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "USMU",
            "iata": "NUX",
            "name": "Новый Уренгой",
            "nameEn": "NOVY URENGOY",
            "city": "Новый Уренгой",
            "cityEn": "NOVY URENGOY",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "USSS",
            "iata": "SVX",
            "name": "Кольцово",
            "nameEn": "KOLTSOVO",
            "city": "Екатеринбург",
            "cityEn": "YEKATERINGBURG",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "UWUU",
            "iata": "UFA",
            "name": "Уфа",
            "nameEn": "UFA",
            "city": "Уфа",
            "cityEn": "UFA",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "UUBC",
            "iata": "KLF",
            "name": "Грабцево",
            "nameEn": "GRABTSEVO",
            "city": "Калуга",
            "cityEn": "KALUGA",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "URFF",
            "iata": "SIP",
            "name": "Симферополь",
            "nameEn": "SIMFEROPOL",
            "city": "Симферополь",
            "cityEn": "SIMFEROPOL",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Simferopol",
            "type": "airport"
        },
        {
            "icao": "UOOO",
            "iata": "NSK",
            "name": "Алыкель",
            "nameEn": "ALYKEL",
            "city": "Норильск",
            "cityEn": "NORILSK",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+7",
            "timezone": "Asia/Krasnoyarsk",
            "type": "airport"
        },
        {
            "icao": "URKK",
            "iata": "KRR",
            "name": "Пашковский",
            "nameEn": "PASHKOVSKY",
            "city": "Краснодар",
            "cityEn": "KRASNODAR",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "URMG",
            "iata": "GRV",
            "name": "Грозный",
            "nameEn": "GROZNY",
            "city": "Грозный",
            "cityEn": "GROZNY",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "USTR",
            "iata": "TJM",
            "name": "Рощино",
            "nameEn": "ROSCHINO",
            "city": "Тюмень",
            "cityEn": "TYUMEN",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "UUEE",
            "iata": "SVO",
            "name": "Шереметьево",
            "nameEn": "SHEREMETYEVO",
            "city": "Москва",
            "cityEn": "MOSCOW",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "URKG",
            "iata": "GDZ",
            "name": "Геленджик",
            "nameEn": "GELENDZHIK",
            "city": "Геленджик",
            "cityEn": "GELENDZHIK",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "USRR",
            "iata": "SGC",
            "name": "Сургут",
            "nameEn": "SURGUT",
            "city": "Сургут",
            "cityEn": "SURGUT",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "URKA",
            "iata": "AAQ",
            "name": "Витязево",
            "nameEn": "VITYAZEVO",
            "city": "Анапа",
            "cityEn": "ANAPA",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "USPP",
            "iata": "PEE",
            "name": "Большое Савино",
            "nameEn": "BOLSHOYE SAVINO",
            "city": "Пермь",
            "cityEn": "PERM",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+5",
            "timezone": "Asia/Yekaterinburg",
            "type": "airport"
        },
        {
            "icao": "UUMO",
            "iata": "OSF",
            "name": "Остафьево",
            "nameEn": "OSTAFYEVO",
            "city": "Москва",
            "cityEn": "MOSCOW",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "UWWW",
            "iata": "KUF",
            "name": "Курумоч",
            "nameEn": "KURUMOCH",
            "city": "Самара",
            "cityEn": "SAMARA",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+4",
            "timezone": "Europe/Samara",
            "type": "airport"
        },
        {
            "icao": "UWGG",
            "iata": "GOJ",
            "name": "Стригино",
            "nameEn": "STRIGINO",
            "city": "Нижний Новгород",
            "cityEn": "NIZHNY NOVGOROD",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        },
        {
            "icao": "UNKL",
            "iata": "KJA",
            "name": "Емельяново",
            "nameEn": "EMELYANOVO",
            "city": "Красноярск",
            "cityEn": "KRASNOYARSK",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+7",
            "timezone": "Asia/Krasnoyarsk",
            "type": "airport"
        },
        {
            "icao": "LFMN",
            "iata": "NCE",
            "name": "Кот Д'Азур",
            "nameEn": "COTE D'AZUR",
            "city": "Ницца",
            "cityEn": "NICE",
            "country": "Франция",
            "countryEn": "FRANCE",
            "utcOffset": "+2",
            "timezone": "Europe/Paris",
            "type": "airport"
        },
        {
            "icao": "LSGG",
            "iata": "GVA",
            "name": "Койнтрин",
            "nameEn": "COINTRIN",
            "city": "Женева",
            "cityEn": "GENEVA",
            "country": "Швейцария",
            "countryEn": "SWITZERLAND",
            "utcOffset": "+2",
            "timezone": "Europe/Paris",
            "type": "airport"
        },
        {
            "icao": "EVRA",
            "iata": "RIX",
            "name": "Скулте",
            "nameEn": "RIGA",
            "city": "Рига",
            "cityEn": "RIGA",
            "country": "Латвия",
            "countryEn": "LATVIA",
            "utcOffset": "+3",
            "timezone": "Europe/Riga",
            "type": "airport"
        },
        {
            "icao": "LCLK",
            "iata": "LCA",
            "name": "Ларнака",
            "nameEn": "LARNACA INTL",
            "city": "Ларнака",
            "cityEn": "LARNACA",
            "country": "Кипр",
            "countryEn": "CYPRUS",
            "utcOffset": "+3",
            "timezone": "Asia/Nicosia",
            "type": "airport"
        },
        {
            "icao": "LOWW",
            "iata": "VIE",
            "name": "Швехат",
            "nameEn": "SCHWECHAT",
            "city": "Вена",
            "cityEn": "VIENNA",
            "country": "Австрия",
            "countryEn": "AUSTRIA",
            "utcOffset": "+2",
            "timezone": "Europe/Vienna",
            "type": "airport"
        },
        {
            "icao": "EGGW",
            "iata": "LTN",
            "name": "Лутон",
            "nameEn": "LUTON",
            "city": "Лондон",
            "cityEn": "LONDON",
            "country": "Великобритания",
            "countryEn": "UK",
            "utcOffset": "+1",
            "timezone": "Europe/London",
            "type": "airport"
        },
        {
            "icao": "EDDM",
            "iata": "MUC",
            "name": "Мюнхен",
            "nameEn": "MUNCHEN",
            "city": "Мюнхен",
            "cityEn": "MUNICH",
            "country": "Германия",
            "countryEn": "GERMANY",
            "utcOffset": "+2",
            "timezone": "Europe/Berlin",
            "type": "airport"
        },
        {
            "icao": "EGLF",
            "iata": "FAB",
            "name": "Фарнборо",
            "nameEn": "FARNBOROUGH",
            "city": "Лондон",
            "cityEn": "LONDON",
            "country": "Великобритания",
            "countryEn": "UK",
            "utcOffset": "+1",
            "timezone": "Europe/London",
            "type": "airport"
        },
        {
            "icao": "LSZH",
            "iata": "ZRH",
            "name": "Цюрих",
            "nameEn": "ZURICH",
            "city": "Цюрих",
            "cityEn": "ZURICH",
            "country": "Швейцария",
            "countryEn": "SWITZERLAND",
            "utcOffset": "+2",
            "timezone": "Europe/Zurich",
            "type": "airport"
        },
        {
            "icao": "LFPB",
            "iata": "LBG",
            "name": "Ле Бурже",
            "nameEn": "LE BOURGET",
            "city": "Париж",
            "cityEn": "PARIS",
            "country": "Франция",
            "countryEn": "FRANCE",
            "utcOffset": "+2",
            "timezone": "Europe/Paris",
            "type": "airport"
        },
        {
            "icao": "LFLB",
            "iata": "CMF",
            "name": "AIX-LES-BAINS",
            "nameEn": "AIX-LES-BAINS",
            "city": "CHAMBERY",
            "cityEn": "CHAMBERY",
            "country": "Франция",
            "countryEn": "FRANCE",
            "utcOffset": "+2",
            "timezone": "Europe/Paris",
            "type": "airport"
        },
        {
            "icao": "UMMS",
            "iata": "MSQ",
            "name": "Минск2",
            "nameEn": "MINSK-2",
            "city": "Минск",
            "cityEn": "MINSK",
            "country": "Беларусь",
            "countryEn": "BELARUS",
            "utcOffset": "+3",
            "timezone": "Europe/Minsk",
            "type": "airport"
        },
        {
            "icao": "OMDW",
            "iata": "DWC",
            "name": "Al Maktoum Intl",
            "nameEn": "Al Maktoum Intl",
            "city": "Al Maktoum Intl",
            "cityEn": "Al Maktoum Intl",
            "country": "ОАЭ",
            "countryEn": "UNITED ARAB EMIRATES",
            "utcOffset": "+4",
            "timezone": "Asia/Dubai",
            "type": "airport"
        },
        {
            "icao": "LYBE",
            "iata": "BEG",
            "name": "Белград",
            "nameEn": "BEOGRAD",
            "city": "Белград",
            "cityEn": "BELGRADE",
            "country": "Сербия",
            "countryEn": "SERBIA",
            "utcOffset": "+2",
            "timezone": "Europe/Belgrade",
            "type": "airport"
        },
        {
            "icao": "LIML",
            "iata": "LIN",
            "name": "Линат",
            "nameEn": "LINATE",
            "city": "Милан",
            "cityEn": "MILAN",
            "country": "Италия",
            "countryEn": "ITALY",
            "utcOffset": "+2",
            "timezone": "Europe/Rome",
            "type": "airport"
        },
        {
            "icao": "UBBB",
            "iata": "GYD",
            "name": "Гейдар Алиев",
            "nameEn": "HEYDAR ALIYEV",
            "city": "Баку",
            "cityEn": "BAKU",
            "country": "Азербайджан",
            "countryEn": "AZERBAIJAN",
            "utcOffset": "+4",
            "timezone": "Asia/Baku",
            "type": "airport"
        },
        {
            "icao": "LLBG",
            "iata": "TLV",
            "name": "Бен Гурион",
            "nameEn": "BEN GURION",
            "city": "Тель Авив",
            "cityEn": "TEL AVIV",
            "country": "Израиль",
            "countryEn": "ISRAEL",
            "utcOffset": "+3",
            "timezone": "Asia/Jerusalem",
            "type": "airport"
        },
        {
            "icao": "VRMM",
            "iata": "MLE",
            "name": "MALE INTL",
            "nameEn": "MALE INTL",
            "city": "HULULE ISLAND",
            "cityEn": "HULULE ISLAND",
            "country": "Мальдивские о-ва",
            "countryEn": "MALDIVES",
            "utcOffset": "+5",
            "timezone": "Indian/Maldives",
            "type": "airport"
        },
        {
            "icao": "EDDB",
            "iata": "SXF",
            "name": "Шенефельд",
            "nameEn": "SCHONEFELD",
            "city": "Берлин",
            "cityEn": "BERLIN",
            "country": "Германия",
            "countryEn": "GERMANY",
            "utcOffset": "+2",
            "timezone": "Europe/Berlin",
            "type": "airport"
        },
        {
            "icao": "UTTT",
            "iata": "TAS",
            "name": "Южный",
            "nameEn": "YUZHNY",
            "city": "Ташкент",
            "cityEn": "TASHKENT",
            "country": "Узбекистан",
            "countryEn": "UZBEKISTAN",
            "utcOffset": "+5",
            "timezone": "Asia/Tashkent",
            "type": "airport"
        },
        {
            "icao": "EETN",
            "iata": "TLL",
            "name": "Таллинн",
            "nameEn": "Lennart Meri",
            "city": "Таллинн",
            "cityEn": "TALLINN",
            "country": "Эстония",
            "countryEn": "ESTONIA",
            "utcOffset": "+3",
            "timezone": "Europe/Tallinn",
            "type": "airport"
        },
        {
            "icao": "EFHK",
            "iata": "HEL",
            "name": "Вантаа",
            "nameEn": "VANTAA",
            "city": "Хельсинки",
            "cityEn": "HELSINKI",
            "country": "Финляндия",
            "countryEn": "FINLAND",
            "utcOffset": "+3",
            "timezone": "Europe/Helsinki",
            "type": "airport"
        },
        {
            "icao": "UAAA",
            "iata": "ALA",
            "name": "Алматы",
            "nameEn": "ALMATY",
            "city": "Алматы",
            "cityEn": "ALMATY",
            "country": "Казахстан",
            "countryEn": "KAZAKHSTAN",
            "utcOffset": "+6",
            "timezone": "Asia/Almaty",
            "type": "airport"
        },
        {
            "icao": "LOWI",
            "iata": "INN",
            "name": "Инсбрук",
            "nameEn": "INNSBRUCK",
            "city": "Инсбрук",
            "cityEn": "INNSBRUCK",
            "country": "Австрия",
            "countryEn": "AUSTRIA",
            "utcOffset": "+2",
            "timezone": "Europe/Vienna",
            "type": "airport"
        },
        {
            "icao": "EYVI",
            "iata": "VNO",
            "name": "Вильнюс",
            "nameEn": "VILNIUS",
            "city": "Вильнюс",
            "cityEn": "VILNIUS",
            "country": "Литва",
            "countryEn": "LITHUANIA",
            "utcOffset": "+3",
            "timezone": "Europe/Vilnius",
            "type": "airport"
        },
        {
            "icao": "UACC",
            "iata": "TSE",
            "name": "НУР-СУЛТАН",
            "nameEn": "НУР-СУЛТАН",
            "city": "НУР-СУЛТАН",
            "cityEn": "НУР-СУЛТАН",
            "country": "Казахстан",
            "countryEn": "KAZAKHSTAN",
            "utcOffset": "+6",
            "timezone": "Asia/Almaty",
            "type": "airport"
        },
        {
            "icao": "LIRP",
            "iata": "PSA",
            "name": "Сан Джусто",
            "nameEn": "SAN GIUSTO - PPR",
            "city": "Пиза",
            "cityEn": "PISA",
            "country": "Италия",
            "countryEn": "ITALY",
            "utcOffset": "+2",
            "timezone": "Europe/Rome",
            "type": "airport"
        },
        {
            "icao": "UUDD",
            "iata": "DME",
            "name": "Домодедово",
            "nameEn": "Domodedovo",
            "city": "Москва",
            "cityEn": "MOSCOW",
            "country": "Россия",
            "countryEn": "RUSSIA",
            "utcOffset": "+3",
            "timezone": "Europe/Moscow",
            "type": "airport"
        }
    ];
}
