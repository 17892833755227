import React from "react";

export default class LegalEntity extends React.Component {
    render() {
        const {item, index} = this.props;

        return (
            <div className="profile-form">
                <div className="form-title">Юридическое лицо {index + 1}</div>
                <div className="form-controls">
                    <div className="form-control">
                        <label>Наименование организации</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls form-controls-3">
                    <div className="form-control">
                        <label>ИНН</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>ОГРН</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>КПП</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls">
                    <div className="form-control">
                        <label>Юридический адрес</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls form-controls-2">
                    <div className="form-control">
                        <label>Наименование банка</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>Расчетный счет</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls form-controls-2">
                    <div className="form-control">
                        <label>Корреспондентский счет</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>БИК</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls form-controls-2">
                    <div className="form-control">
                        <label>Код ОКПО</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>ОКВЭД</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-title">Информация о подписанте</div>
                <div className="form-controls">
                    <div className="form-control">
                        <label>ФИО</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-controls form-controls-2">
                    <div className="form-control">
                        <label>Должность</label>
                        <input type="text"/>
                    </div>
                    <div className="form-control">
                        <label>Документ-основание</label>
                        <input type="text"/>
                    </div>
                </div>
                <div className="form-company-bottom">
                    <label className="control-checkbox"><input type="checkbox"/><i></i> Использовать в качестве
                        основного
                        для оплаты</label>
                    <div className="form-link"><i className="icon icon-edit"></i> Редактировать</div>
                    <div className="form-link" onClick={this.props.onDeleteLegalEntity}><i className="icon icon-delete"></i> Удалить</div>
                </div>
            </div>
        );
    }
}