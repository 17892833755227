import React from "react";

export default class OrderReservBlock extends React.Component {
    render() {

        console.log(this.props);

        return (
            <div className="reserv-block">
                <div className="reserv-h2">Выбранный рейс забронирован до 12:40
                    29.11.2019
                </div>
                <div className="reserv-info">
                    <div>Номер заказа: UEI-283108</div>
                    <div className="reserv-price">20 000 000 &#8381;</div>
                </div>
                <div className="reserv-summary">
                    <div>Итого:</div>
                    <div className="reserv-price">20 000 000 &#8381;</div>
                </div>
                <b>Ссылку на данное предложение вы сможете найти в Личном
                    кабинет, в
                    разделе «Бронь»</b>
            </div>
        )
    }
}