import React from "react";


export default class Loading extends React.Component {
    render() {
        return (
            <div className="loading" style={{display: "block"}}>
                <div className="loading-window">
                    <div className="loading-body">
                        <div className="loading-title">{this.props.text}</div>
                        <div className="loading-progress">
                            <div className="loading-progress-bar" style={{width: "60%"}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}