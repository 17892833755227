import React, {Fragment} from "react";
import {ModalContext} from "./Page";
import {LoginProvider} from "./providers/LoginProvider";
import DataProvider, {siteApiHost} from "./DataProvider";
import {setAccessToken} from "./Auth";
import {Redirect} from "react-router-dom";

const Error = {};

Error.Error = class extends React.Component {
    render() {
        switch (this.props.type.toString()) {
            case "400":
                return (<Error.Auth {...this.props}/>);
            case "401":
                return (<Error.Unauth {...this.props}/>);
            case "402":
                return (<Error.HasNotPassword {...this.props} />);
            case "403":
                return (<Error.NotConfirmedUser {...this.props} />);
            default:
                return (<Error.Base message={"Ошибка"} {...this.props}/>);
        }
    }
};

Error.HasNotPassword = class extends React.Component {
    constructor(props) {
        super(props);
        Error.HasNotPassword.contextType = ModalContext;
    }

    componentDidMount() {
        this.context.showNewPasswordModal();
    }

    render() {
        return <Fragment/>
    }
};

Error.NotConfirmedUser = class extends React.Component {
    constructor(props) {
        super(props);
        Error.NotConfirmedUser.contextType = ModalContext;
    }

    componentDidMount() {
        this.context.showConfirmModal();
    }

    render() {
        return <LoginProvider init={true}/>
    }
};

Error.Auth = class extends React.Component {
    constructor(props) {
        super(props);
        Error.Auth.contextType = ModalContext;
    }

    componentDidMount() {
        this.context.showLoginModal();
    }

    render() {
        return <LoginProvider init={true}/>
    }
};

Error.Unauth = class extends React.Component {
    state = {
        refreshed: false,
    };

    constructor(props) {
        super(props);
        Error.Unauth.contextType = ModalContext;
    }

    onTokenRefreshed = (data) => {
        setAccessToken(data);
        this.setState({refreshed: true})
    };

    render() {
        return (
            <DataProvider
                authToken={true}
                tokenDead={true}
                method={"POST"}
                url={`${siteApiHost}auth/token/refresh`}
                onDataLoaded={this.onTokenRefreshed}>

                <Redirect push to={"/lk/user/edit"}/>

            </DataProvider>
        )
    }
};


Error.Base = class extends React.Component {
    render() {
        return (
            <div className={"form-control"}>
                <div className={"form-error"}>{this.props.message}</div>
            </div>
        )
    }
};

export default Error.Error;