import React from "react";
import DataProvider, {siteApiHost} from "../DataProvider";
import {setAccessToken} from "../Auth";
import {ModalContext} from "../Page";

export class RegProvider extends React.Component {
    constructor(props) {
        super(props);
        RegProvider.contextType = ModalContext
    }

    onDataLoaded = (data) => {
        setAccessToken(data);
        this.context.showConfirmModal();
    };

    render() {
        return (
            <DataProvider loadingText={"Регистрируем"} method={"POST"}
                          url={`${siteApiHost}auth/registration`}
                          body={this.props.form}
                          onDataLoaded={this.onDataLoaded}/>
        );
    }
}