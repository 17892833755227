export default function companies() {
    return (
        {
            "default": "«Юпитер»",
            "weltall": "«Вельталь-авиа»",
            "jetexpress": "«Джет Экспресс»",
            "aviaservice": "«Авиасервис»",
            "pobeda": "«Победа»"
        }
    )
}

