import React from "react";
import Form from "../Form";
import {Modal} from "../Modals";
import {LoginProvider} from "../providers/LoginProvider";
import {ModalContext} from "../Page";

export default class ModalLogin extends React.Component {
    constructor(props) {
        super(props);
        ModalLogin.contextType = ModalContext
    }

    render() {
        const {showForgotModal, showRegModal} = this.context;

        return (
            <Modal title={"Вход в личный кабинет"}
                   type={"login"} {...this.props}>

                <Form.Form {...this.props}>
                    <Form.EmailControl/>
                    <Form.PasswordControl/>

                    <Form.Submit label={"Войти"} provider={<LoginProvider/>}/>

                    <div className="form-links">
                        <div className="form-link"><a
                            className="form-link-password"
                            onClick={showForgotModal}>Забыли
                            пароль?</a></div>

                        <div className="form-link"><a className="form-link-reg"
                                                      onClick={showRegModal}>Зарегистрироваться</a>
                        </div>
                    </div>

                </Form.Form>
            </Modal>
        )
    }
}