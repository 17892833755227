import React from "react";
import "../css/weltall.css";
import DataProvider, {
    coreApiHost,
    siteApiHost
} from "../components/DataProvider";

import OrderFlightBlock from "./order/components/OrderFlightBlock";
import OrderOrderBlock from "./order/components/OrderOrderBlock";
import OrderReservBlock from "./order/components/OrderReservBlock";
import Page from "../components/Page";
import Logic from "../components/Logic";

export default class OrderPage extends React.Component {
    state = {
        card: null,
        order: null
    };

    onDataLoaded = (card) => {
        this.setState({card: card});
    };

    onOrderDataLoaded = (result) => {
        this.setState({card: {}, order: result.order})
    };

    onOrderCreated = (order) => {
        this.setState({order: order});
    };

    onOrderCancelled = () => {
        this.setState({order: null})
    };

    render() {
        const {order, card} = this.state;
        const {cid: cardID, oid: orderID} = this.props.match.params;

        const url = `${siteApiHost}orders/${orderID}`;
        const url1 = `${coreApiHost}cards/${cardID}`;
        
        return (
            <Page showHeader={true} mainClass={"order_page"}>
                <Logic.Unless item={order}>
                    <Logic.If item={orderID}>
                        <DataProvider authToken={true}
                                      onDataLoaded={this.onOrderDataLoaded}
                                      url={url}/>
                    </Logic.If>
                </Logic.Unless>

                <Logic.If item={order}>
                    <OrderReservBlock order={order}/>
                </Logic.If>

                <Logic.If item={card}>
                    <OrderFlightBlock card={card}/>

                    <OrderOrderBlock
                        onOrderCancelled={this.onOrderCancelled}
                        onOrderCreated={this.onOrderCreated}
                        card={card}
                        order={order}
                        {...this.props} />
                </Logic.If>

                <Logic.Unless item={card}>
                    <Logic.If item={cardID}>
                        <DataProvider authToken={true}
                                      onDataLoaded={this.onDataLoaded}
                                      url={url1}/>
                    </Logic.If>

                        <h2 className={"empty-result"}>Пусто&hellip;</h2>
                </Logic.Unless>
            </Page>
        )
    }
}