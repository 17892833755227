import React from "react";
import TopBlock from "./index/components/TopBlock";
import SearchBlock from "./index/components/SearchBlock";
import AttentionBlock from "./index/components/AttentionBlock";
import FlightsProvider from "./index/components/FlightsProvider";
import airports from "../airports";
import Page from "../components/Page"
import "../css/style.css";

export default class IndexPage extends React.Component {
    state = {
        query: null,
    };

    onSearch = (query) => {
        this.setState({query: query});
    };

    render() {
        return (
            <Page showHeader={true} mainClass={"common_page"}>
                <TopBlock/>
                <SearchBlock airports={airports} onSearch={this.onSearch}/>
                <AttentionBlock/>
                <FlightsProvider query={this.state.query} {...this.props} />
            </Page>
        )
    }
}