import React, {Fragment} from "react";
import ModalLogin from "./modals/ModalLogin";
import ModalReg from "./modals/ModalReg";
import ModalForgot from "./modals/ModalForgot";
import ModalPay from "./modals/ModalPay";
import Logic from "./Logic"
import {ModalContext} from "./Page";
import ModalConfirm from "./modals/ModalConfirm";
import ModalNewPassword from "./modals/ModalNewPassword";

export class Modal extends React.Component {
    constructor(props) {
        super(props);
        Modal.contextType = ModalContext
    }

    onModalClose = () => {
        document.body.style.overflow = 'unset';
        this.context.onModalClose();
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
    }

    render() {
        const {type, children, title, subtitle} = this.props;
        const {show} = this.context;

        return (
            <Logic.If item={show === type}>
                <div className="modal modal-active common_page">
                    <div className="modal-window">
                        <div className="modal-close" title="Закрыть"
                             onClick={this.onModalClose}/>

                        <div className="modal-body">
                            <div className="modal-title">{title}</div>

                            <Logic.If item={subtitle}>
                                <div className="modal-subtitle">{subtitle}</div>
                            </Logic.If>

                            {children}
                        </div>
                    </div>
                </div>
            </Logic.If>
        )
    }
}

export default class Modals extends React.Component {
    constructor(props) {
        super(props);
        Modals.contextType = ModalContext
    }

    render() {
        const {show} = this.context;

        if (show === "login") {
            return <ModalLogin {...this.props}/>
        } else if (show === "reg") {
            return <ModalReg {...this.props}/>
        } else if (show === "forgot") {
            return <ModalForgot {...this.props} />
        } else if (show === "pay") {
            return <ModalPay  {...this.props} />
        } else if (show === "confirm") {
            return <ModalConfirm  {...this.props} />
        } else if (show === "newpassword") {
            return <ModalNewPassword  {...this.props} />
        } else {
            return <Fragment/>
        }
    }
}