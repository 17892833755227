import React, {Fragment} from "react";

const Logic = {};

Logic.If = class extends React.Component {
    makeChildren = () => {
        return this.props.children;
    };

    render() {
        const {item} = this.props;

        if (Array.isArray(item)) {
            if (item.length) {
                return this.makeChildren()
            } else {
                return <Fragment/>
            }
        } else if (typeof item === "function") {
            try {
                if (item()) {
                    return this.makeChildren();
                } else {
                    return <Fragment/>
                }
            } catch (e) {
                return <Fragment/>
            }
        } else if (this.props.item) {
            return this.makeChildren();
        }

        return <Fragment/>
    }
};

Logic.Unless = class extends React.Component {
    render() {
        const {item} = this.props;

        if (Array.isArray(item) && !item.length) {
            return this.props.children
        } else if (typeof item === "function") {
            return item();
        } else if (!this.props.item) {
            return this.props.children
        }

        return <Fragment/>
    }
};

export default Logic;