import React, {Fragment} from "react";
import FlightBlock from "./orderFlightBlock/FlightBlock";
import Media from "react-media";


export default class OrderFlightBlock extends React.Component {
    render() {
        const {card} = this.props;

        return (
            <Media queries={{
                medium: "(max-width: 1199px)",
                large: "(min-width: 1200px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.medium && <FlightBlock  size = "Md" {...this.props} />}
                        {matches.large && <FlightBlock size = "Lg" {...this.props} />}
                    </Fragment>
                )}
            </Media>
        )
    }
}