import React from "react";
import Form from "../Form";
import {NewPasswordProvider} from "../providers/NewPasswordProvider";
import {Modal} from "../Modals";
import {ModalContext} from "../Page";

export default class ModalNewPassword extends React.Component {
    constructor(props) {
        super(props);
        ModalNewPassword.contextType = ModalContext;
    }

    render() {
        return (
            <Modal
                title={<span>Ваш email подтвержден.<br/> Введите пароль для входа</span>}
                type={"newpassword"}
                {...this.props}>
                <Form.Form>
                    <Form.PasswordControl/>

                    <Form.PasswordControl name={"password_confirm"}
                                          placeholder={"Повторите пароль"}/>

                    <Form.Submit label={"Подтвердить"}
                                 provider={<NewPasswordProvider/>}/>
                </Form.Form>
            </Modal>
        )
    }
}