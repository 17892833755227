import React, {Fragment} from "react";
import LegalEntity from "./LegalEntity";
import Logic from "../../../components/Logic";

export default class LkEditProfile extends React.Component {
    state = {
        legal_details: [],
    };

    addLegalEntity = () => {
        this.state.legal_details.push([]);
        this.setState({legal_details: this.state.legal_details})
    };

    onDeleteLegalEntity = () => {
        this.state.legal_details.pop();
        this.setState({legal_details: this.state.legal_details})
    };

    componentDidMount() {
        this.setState({legal_details: this.props.user.legal_details || []})
    }

    render() {
        const {user} = this.props;

        return (
            <Fragment>
                <div className="profile-form">
                    <div className="form-title">Основная информация</div>
                    <div className="form-controls">
                        <div className="form-control">
                            <label>ФИО</label>
                            <input type="text" value={user.name}/>
                        </div>
                        {/*<div className="form-control">*/}
                        {/*    <label>Фамилия</label>*/}
                        {/*    <input type="text" value={user.lastname}/>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="form-controls form-controls-2">*/}
                    {/*    <div className="form-control">*/}
                    {/*        <label>Страна</label>*/}

                    {/*        <Select options={[{value: "РФ", label: "Российская федерация"}, {value: "UK", label: "Поганый бендеровец"}]} onChange={this.setCurrency}/>*/}

                    {/*        /!*<div className="control">*!/*/}
                    {/*        /!*    <button className="button-dropdown">Российская Федерация</button>*!/*/}
                    {/*        /!*    <div className="dropdown">*!/*/}
                    {/*        /!*        <div className="dropdown-item">Российская Федерация</div>*!/*/}
                    {/*        /!*        <div className="dropdown-item">Российская Федерация</div>*!/*/}
                    {/*        /!*        <div className="dropdown-item">Российская Федерация</div>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}

                    {/*    <div className="form-control">*/}
                    {/*        <label>Город</label>*/}
                    {/*        <input type="text" value={user.city}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="form-link"><i className="icon icon-foto"></i> Изменить фото профиля</div>*/}
                </div>

                <div className="profile-form">
                    <div className="form-title">Контактная информация</div>
                    <div className="form-controls form-controls-2">
                        <div className="form-control">
                            <label>Email</label>
                            <input type="text" value={user.email}/>
                        </div>
                        <div className="form-control">
                            <label>Телефон</label>
                            <input type="text" placeholder="+7"
                                   value={user.phone}/>
                        </div>
                    </div>
                    <div className="form-link"><i
                        className="icon icon-password"></i> Сменить пароль
                    </div>
                </div>

                {/*<div className="profile-form">*/}
                {/*    <div className="form-title">Контактная информация</div>*/}
                {/*    <div className="form-controls form-controls-2">*/}
                {/*        <div className="form-control">*/}
                {/*            <label>Логин</label>*/}
                {/*            <input type="text" value={user.email}/>*/}
                {/*        </div>*/}
                {/*        /!*<div className="form-control">*!/*/}
                {/*        /!*    <label>Пароль</label>*!/*/}
                {/*        /!*    <input type="password"/>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*    <div className="form-link"><i className="icon icon-password"></i> Сменить пароль</div>*/}
                {/*</div>*/}

                <Logic.Unless item={user.type === "physical"}>
                    {(this.state.legal_details || []).map((item, index) => (
                        <LegalEntity index={index} item={item}
                                     onDeleteLegalEntity={this.onDeleteLegalEntity}/>
                    ))}

                    <div className="profile-form">
                        <div className="form-company-add">
                            <div className="form-title">Добавить юридическое
                                лицо
                            </div>
                            <button onClick={this.addLegalEntity}></button>
                        </div>
                    </div>
                </Logic.Unless>
            </Fragment>
        )
    }
}