import React, {Fragment} from "react";
import FlightBlockLgRow from "./FlightBlockLgRow";
import _ from "underscore";
import NumberFormat from "react-number-format";
import Select from "react-select";
import {
    currency_dict,
    currency_dict_to_select
} from "../../../../components/Helpers";

export default class FlightsBlockLg extends React.Component {
    state = {
        currency: "rub",
    };

    price_sum = (card) => {
        const {currency} = this.state;

        const price = card.routes.filter(route => route).reduce(function (a, r) {
            return a + r.price[currency]
        }, 0);

        return (<NumberFormat value={price} displayType={'text'}
                              thousandSeparator={" "} suffix={currency_dict[currency]}/>);
    };

    setCurrency = (e) => {
        this.setState({currency: e.value});
    };

    render() {
        const {searchId, items} = this.props;

        return (
            <div className="flights-block flights-block-lg">
                <div className="flights-head">
                    <div className="flight-table">
                        <table>
                            <tr>
                                <th className="flight-plane">Воздушное судно</th>
                                <th className="flight-logo">Авиакомпания</th>
                                <th className="flight-airport">Аэропорт вылета</th>
                                <th className="flight-date">Дата и время вылета<br/> (время местное)</th>
                                <th className="flight-airport">Аэропорт прилета</th>
                                <th className="flight-date">Дата и время прилета<br/> (время местное)</th>
                                <th className="flight-duration">В пути</th>
                                <th className="flight-price">
                                    <Select options={currency_dict_to_select} onChange={this.setCurrency}
                                            defaultValue={_.findWhere(currency_dict_to_select, {value: "rub"})}/>

                                    {/*<div className="control">*/}
                                    {/*    <button className="button-dropdown"><i data-currency="rub"></i></button>*/}
                                    {/*    <div className="dropdown">*/}
                                    {/*        <div className="dropdown-item"><i data-currency="rub"></i></div>*/}
                                    {/*        <div className="dropdown-item"><i data-currency="usd"></i></div>*/}
                                    {/*        <div className="dropdown-item"><i data-currency="eur"></i></div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>

                {items.map(item => (
                    <Fragment>
                        <div className="flights-body">
                            <div className="flight-table">
                                <table>
                                    {item.routes.filter(route => route).map(route => (
                                        <FlightBlockLgRow currency={this.state.currency} cardId={item.id} route={route}
                                                          searchId={searchId}/>
                                    ))}
                                </table>
                            </div>
                            <div className="flight-select">Выбрать рейс</div>
                        </div>

                        <div className="flights-summary">Итого: {this.price_sum(item)}</div>

                    </Fragment>
                ))}

            </div>
        )
    }
}