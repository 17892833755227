import React from "react";
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import airports from "../../../airports";
import moment from "moment";

class AirportSelect extends React.Component {
    label = (airport) => {
        return `${airport["city"]} ${airport["name"]} (${airport["icao"]} ${airport["iata"]})`;
    };

    options = () => {
        return airports().map(airport => {
            return {value: airport["icao"], label: this.label(airport)}
        });
    };

    onChange = (e) => {
        this.props.onChange(this.props.name, e.value);
    };

    render() {
        return (
            <Select styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({...provided, zIndex: 9999})
            }} options={this.options()} onChange={this.onChange}/>
        )
    }
}

export default class SearchBlock extends React.Component {
    state = {
        departure_airport: null,
        arrival_airport: null,
        pax_there: 1,
        pax_back: 1,
        needReturnRoute: false,
        submitDisabled: true,
        departure_date_back1: null,
        departure_date_back2: null,
        departure_date_there1: null,
        departure_date_there2: null
    };

    formatDate = (date, time) => {
        if (!date || !time) {
            return "";
        }

        const _date = moment(date).format("YYYY-MM-DD");
        const _time = moment(time).format("HH:mm");

        return `${_date}T${_time}Z`;
    };

    search = (event) => {
        const {
            departure_airport, arrival_airport, pax_there, departure_date_there1, departure_date_there2, departure_date_back2, departure_date_back1, pax_back
        } = this.state;

        var searchOptions = {
            departure_airport: departure_airport,
            arrival_airport: arrival_airport,
            pax_there: pax_there,
            departure_date_there: this.formatDate(departure_date_there1, departure_date_there2),
        };

        if (this.state.needReturnRoute) {
            searchOptions = Object.assign({}, searchOptions, {
                pax_back: pax_back,
                departure_date_back: this.formatDate(departure_date_back1, departure_date_back2)
            });
        }

        this.props.onSearch(searchOptions);
        event.preventDefault();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.canSubmit() && this.state.submitDisabled === true) {
            this.setState({submitDisabled: false});
        }

        if (!this.canSubmit() && this.state.submitDisabled === false) {
            this.setState({submitDisabled: true});
        }
    }

    canSubmit = () => {
        const {
            departure_airport, arrival_airport, pax_there, departure_date_there1, departure_date_there2, departure_date_back2, departure_date_back1, pax_back
        } = this.state;

        var predicate =
            departure_airport
            && arrival_airport
            && departure_airport !== arrival_airport
            && pax_there > 0
            && departure_date_there1
            && departure_date_there2;

        if (this.state.needReturnRoute) {
            predicate =
                predicate
                && pax_back > 0
                && departure_date_back1
                && departure_date_back2
                && departure_date_back1 >= departure_date_there1;
        }

        return !!predicate;
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    setByName = (name, date) => {
        this.setState({
            [name]: date
        });
    };

    showReturnForm = () => {
        this.setState({
            needReturnRoute: !this.state.needReturnRoute
        })
    };

    paxMinus = (e) => {
        const pax = this.state[e.target.getAttribute('data-name')] - 1;
        this.setState({
            [e.target.getAttribute('data-name')]: pax > 0 ? pax : 0
        });
    };

    paxPlus = (e) => {
        this.setState({
            [e.target.getAttribute('data-name')]: this.state[[e.target.getAttribute('data-name')]] + 1
        });
    };

    render() {
        const {
            departure_airport, arrival_airport, pax_there, departure_date_there1, departure_date_there2, departure_date_back2, departure_date_back1, pax_back
        } = this.state;

        const returnResultClass = !this.state.needReturnRoute ? 'filter-controls hidden' : 'filter-controls';

        return (
            <div className="search-block">
                <div className="form-block">
                    <div className="tabs-body">
                        <form className="filter">
                            <div className="filter-airports">
                                <div className="filter-airport filter-airport-from">
                                    <label>Аэропорт вылета</label>

                                    <AirportSelect name={"departure_airport"} onChange={this.setByName}/>


                                    {/*<input className="date control-input control-input-big" name={"departure_airport"}*/}
                                    {/*       onChange={this.onChange} value={departure_airport}/>*/}

                                    {/*<div className="filter-airport-name">Шереметьево (Москва)</div>*/}
                                    {/*<div className="filter-airport-code">UUEE SVO</div>*/}
                                    {/*<div className="filter-airport-hint">*/}
                                    {/*    <div className="filter-airport-name">Шереметьево<br/> (Москва)</div>*/}
                                    {/*    <div className="filter-airport-code">UUEE SVO</div>*/}
                                    {/*</div>*/}

                                    <div className="filter-airport-none">
                                        <i className="icon icon-question"></i>
                                        <div className="filter-airport-none-link">Я не нашёл нужный мне аэропорт</div>
                                        <div className="filter-airport-none-hint">Если вы не нашли в списке нужный вам
                                            аэропорт, пожалуйста, свяжитесь с <a
                                                href="#">авиакомпаниями-партнёрами</a> напрямую
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-track"><i></i></div>
                                <div className="filter-airport filter-airport-to">
                                    <label>Аэропорт назначения</label>
                                    <AirportSelect name={"arrival_airport"} onChange={this.setByName}/>

                                    {/*<input onChange={this.onChange} className="date control-input control-input-big"*/}
                                    {/*       name={"arrival_airport"} value={arrival_airport}/>*/}
                                    {/*<div className="filter-airport-name">Ааппилатток-Нанорталик (Ааппилатток-Нанорталик)*/}
                                    {/*</div>*/}
                                    {/*<div className="filter-airport-code">UUEE SVO</div>*/}
                                    {/*<div className="filter-airport-hint">*/}
                                    {/*    <div*/}
                                    {/*        className="filter-airport-name">Ааппилатток-Нанорталик<br/> (Ааппилатток-Нанорталик)*/}
                                    {/*    </div>*/}
                                    {/*    <div className="filter-airport-code">UUEE SVO</div>*/}
                                    {/*</div>*/}
                                    <div className="filter-airport-none">
                                        <i className="icon icon-question"></i>
                                        <div className="filter-airport-none-link">Я не нашёл нужный мне аэропорт</div>
                                        <div className="filter-airport-none-hint">Если вы не нашли в списке нужный вам
                                            аэропорт, пожалуйста, свяжитесь с <a
                                                href="#">авиакомпаниями-партнёрами</a> напрямую
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="filter-controls">
                                <div className="filter-control">
                                    <label>Дата вылета</label>
                                    <div className="control control-datepicker">
                                        <DatePicker className="date control-input control-input-big"
                                                    selected={departure_date_there1}
                                                    onChange={date => this.setByName("departure_date_there1", date)}
                                                    dateFormat="dd.MM.Y"/>
                                    </div>
                                </div>
                                <div className="filter-control">
                                    <label>Время вылета (время местное)</label>
                                    <div className="control control-datepicker">
                                        <DatePicker
                                            className="time control-input control-input-big"
                                            selected={departure_date_there2}
                                            onChange={date => this.setByName("departure_date_there2", date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            timeFormat="HH:00"
                                            dateFormat="HH:00"
                                        />
                                    </div>
                                </div>
                                <div className="filter-control">
                                    <label>Количество пассажиров</label>
                                    <div className="control control-number">
                                        <span className="control-number-minus" data-name={"pax_there"}
                                              onClick={this.paxMinus}>&ndash;</span>

                                        <input readOnly type="number" className="control-input control-input-big"
                                               name="pax_there" onChange={this.onChange}
                                               value={pax_there} min="1"/>

                                        <span className="control-number-plus" data-name={"pax_there"}
                                              onClick={this.paxPlus}>+</span>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-checkbox">
                                <label className="control-checkbox" id="need-return-flight"><input
                                    type="checkbox" onChange={this.showReturnForm}/><i></i> Нужен обратный рейс</label>
                            </div>

                            <div className={returnResultClass}>
                                <div className="filter-control">
                                    <label>Дата вылета</label>
                                    <div className="control control-datepicker">
                                        <DatePicker className="date control-input control-input-big"
                                                    selected={departure_date_back1}
                                                    onChange={date => this.setByName("departure_date_back1", date)}
                                                    dateFormat="dd.MM.Y"/>
                                    </div>
                                </div>
                                <div className="filter-control">
                                    <label>Время вылета (время местное)</label>
                                    <div className="control control-datepicker">
                                        <DatePicker
                                            className="time control-input control-input-big"
                                            selected={departure_date_back2}
                                            onChange={date => this.setByName("departure_date_back2", date)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            timeFormat="HH:00"
                                            dateFormat="HH:00"
                                        />
                                    </div>
                                </div>
                                <div className="filter-control">
                                    <label>Количество пассажиров</label>
                                    <div className="control control-number">
                                        <span className="control-number-minus" data-name="pax_back"
                                              onClick={this.paxMinus}>&ndash;</span>

                                        <input readOnly type="number" className="control-input control-input-big"
                                               name="pax_back" onChange={this.onChange}
                                               value={pax_back} min="1"/>

                                        <span className="control-number-plus" data-name="pax_back"
                                              onClick={this.paxPlus}>+</span>
                                    </div>
                                </div>
                            </div>

                            <div className="filter-bottom">
                                <div className="filter-submit">

                                    <button disabled={this.state.submitDisabled} type="submit"
                                            onClick={this.search}>Найти
                                    </button>

                                </div>
                                <div className="filter-links">
                                    <div><i className="icon icon-oferta"></i><a href="#">Договор-оферта</a></div>
                                    <div><i className="icon icon-rules"></i><a className="" href="#">Правила пользования
                                        сервисом</a></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}