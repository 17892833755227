import React, {Fragment} from "react";
import Loading from "./Loading";
import {accessToken, refreshToken} from "./Auth";
import NJError from "./Error";
import {ModalContext} from "./Page";

export const siteApiHost = 'https://site-api.najet.ru/api/';
export const coreApiHost = 'https://core.najet.ru/api/';

export default class DataProvider extends React.Component {
    constructor(props) {
        super(props);
        DataProvider.contextType = ModalContext;
    }

    state = {
        status: 0,
        error: null,
        isLoaded: true,
        tokenDead: false,
        data: null,
    };

    componentDidMount() {
        this.setState({isLoaded: false});

        const requestOptions = {
            method: this.props.method || "GET",

            headers: new Headers({
                'Content-Type': 'application/json',
            }),
        };

        if (this.props.method === "POST" || this.props.method === "PUT") {
            requestOptions.body = JSON.stringify(this.props.body);
        }

        if (this.props.authToken) {
            const sendingToken = this.props.tokenDead ? refreshToken() : accessToken();

            if (sendingToken) {
                requestOptions.headers.append("Authorization", `Bearer ${sendingToken}`);
            }
        }

        fetch(this.props.url, requestOptions)
            .then(res => {
                this.setState({status: res.status});
                return res;
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                    });

                    this.context.setData(result);

                    const {onDataLoaded} = this.props;
                    onDataLoaded && onDataLoaded(result);
                },

                (error) => {
                    this.setState({isLoaded: true, error});
                }
            )
    }

    render() {
        const {isLoaded, status, data} = this.state;
        const {loadingText} = this.props;

        if (!isLoaded) {
            return <Loading text={loadingText}/>
        } else if (status >= 200 && status <= 299) {
            return this.props.children || <Fragment/>;
        } else {
            return (<NJError type={status} {...this.props} data={data}/>);
        }
    }
}