import React from "react";
import DataProvider, {siteApiHost} from "../DataProvider";
import Logic from "../Logic";

export class LoginProvider extends React.Component {
    onSubmitted = (data) => {
        console.log(data);
    };

    render() {
        return (
            <Logic.Unless item={this.props.init}>
                <DataProvider body={this.props.form} method={"POST"}
                              url={`${siteApiHost}auth/login`}
                              loadingText={"Авторизуемся"}
                              onDataLoaded={this.props.onSubmitted}/>
            </Logic.Unless>
        )
    }
}