import React from "react";
import configVariants from "../../../../configVariants";

export default class FlightBlockRowLg extends React.Component {
    render() {
        const {
            order,
            route,
            aircraft_type,
            aircraft,
            operatorLogo,
            departure_airport,
            departure_date,
            departure_time,
            arrival_airport,
            arrival_date,
            arrival_time,
            orderType,
            pax_without_text,
            price,
            flight_duration,
            index,
            aircraft_year_of_creation,
            aircraft_year_of_overhaul,
            aircraft_year_of_renewal,
            max_pax_without_text,
        } = this.props;

        return (
            <div className={"flight-block" + (index > 0 ? ' flight-block2' : ' ')}>
                <div className="flight-h1"><i></i> {index > 0 ? 'Обратно' : 'Туда'}</div>
                <div className="flight-h2">
                  Авиакомпания {configVariants()[route.operator]} <img src="img/weltall/logo.png" alt=""/>
                </div>
                <div className="flight-lg">
                    <table>
                        <tr>
                            <th>Аэропорт вылета</th>
                            <th>Дата и время вылета<br/> (время местное)</th>
                            <th>Аэропорт прилета</th>
                            <th>Дата и время прилета<br/> (время местное)</th>
                            <th>В пути</th>
                            <th>Количество пассажиров</th>
                            <th>Цена</th>
                        </tr>
                        <tr>
                            <td>{departure_airport().name}<br/><small>{departure_airport().city}</small></td>
                            <td>{departure_date()}<br/><small>{departure_time()}</small></td>
                            <td>{arrival_airport().name}<br/><small>{arrival_airport().city}</small></td>
                            <td>{arrival_date()}<br/><small>{arrival_time()}</small></td>
                            <td>{flight_duration()}</td>
                            <td>{pax_without_text()}</td>
                            <td className="nowrap">{price()}</td>
                        </tr>
                    </table>
                </div>

                <div className="flight-h2">Воздушное судно</div>
                <div className="flight-lg">
                    <table>
                        <tr>
                            <th>Номер борта</th>
                            <th>Тип ВС</th>
                            <th>Количество мест</th>
                            <th>Год выпуска ВС</th>
                            <th>Год капитального ремонта</th>
                            <th>Год обновления салона</th>
                        </tr>
                        <tr>
                            <td>{aircraft()}</td>
                            <td>{aircraft_type()}</td>
                            <td>{max_pax_without_text()}</td>
                            <td>{aircraft_year_of_creation()}</td>
                            <td>{aircraft_year_of_overhaul()}</td>
                            <td>{aircraft_year_of_renewal()}</td>
                        </tr>
                    </table>
                </div>

                <div className="gallery">
                    <img src={route.aircraft_picture_url} alt=""/>
                    <div className="gallery-slider">
                        <div className="gallery-slider-inner">
                            <img src="img/planes/big1.jpg" alt=""/>
                            <img src="img/planes/big2.jpg" alt=""/>
                            <img src="img/planes/big3.jpg" alt=""/>
                        </div>
                        <a className="gallery-slider-prev"></a>
                        <a className="gallery-slider-next"></a>
                    </div>
                </div>
            </div>
        )
    }
}