import React from "react";
import {NavLink} from "react-router-dom";

export default function LkSidebar() {
    const menu = [
        {path: "user/edit", label: "Редактировать профиль"},
        {path: "completed", label: "Завершённые рейсы"},
        {path: "cancelled_paid", label: "Отмененные рейсы"},
        {path: "cancelled_booked", label: "Отмененное бронирование"},
        {path: "booked", label: "Бронь"},
        {path: "paid", label: "Оплаченые"},
    ];

    return (
        <div className="lk-sidebar">
            <div className="account-block">
                {/*<img src="/img/users/avatar.png" className="account-avatar"/>*/}
                <div className="account-menu">
                    {menu.map(item => (
                        <NavLink to={`/lk/${item.path}`} activeClassName={"active"}>{item.label}</NavLink>
                    ))}
                </div>
            </div>
        </div>
    )
}