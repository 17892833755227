const access_token = "access_token";
const refresh_token = "refresh_token";
const new_password_token = "new_password_token";

export function isLoggined() {
    return localStorage.hasOwnProperty(access_token);
}

export function logout() {
    localStorage.removeItem(access_token);
}

function getToken(name) {
    const token = localStorage.getItem(name);

    if (token !== "undefined" && token) {
        return token
    }
}

export function accessToken() {
    return getToken(access_token)
}

export function refreshToken() {
    return getToken(refresh_token);
}

export function newPasswordToken() {
    return getToken(new_password_token);
}

export function setAccessToken(data) {
    if (data.hasOwnProperty(access_token)) {
        localStorage.setItem(access_token, data[access_token]);
    }

    if (data.hasOwnProperty(refresh_token)) {
        localStorage.setItem(refresh_token, data[refresh_token]);
    }

    if (data.hasOwnProperty(new_password_token)) {
        localStorage.setItem(new_password_token, data[new_password_token]);
    }
}