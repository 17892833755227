import React, {Fragment} from "react";
import LkSidebar from "./lk/components/LkSidebar";
import LkEditProfile from "./lk/components/LkEditProfile";
import {Route} from "react-router-dom";
import OrdersBlock from "./lk/components/OrdersBlock";
import DataProvider, {siteApiHost} from "../components/DataProvider";
import Page from "../components/Page";

class OrderTypeResolver extends React.Component {
    state = {
        orders: null,
    };

    onOrdersLoaded = (result) => {
        this.setState({orders: result});
    };

    render() {
        const {orders_type} = this.props.match.params;

        if (!this.state.orders) {
            return (
                <DataProvider authToken={true} loadingText={"Идет загрузка заказов"}
                              url={`${siteApiHost}orders`}
                              onDataLoaded={this.onOrdersLoaded}>
                    <Fragment/>
                </DataProvider>
            )
        } else {
            return <OrdersBlock orders_type={orders_type} items={this.state.orders[orders_type]}/>
        }
    }
}

export default class LkPage extends React.Component {
    state = {
        userData: null,
    };

    onUserDataLoaded = (result) => {
        this.setState({userData: result});
    };

    render() {
        return (
            <Page showHeader={true} mainClass={"common_page"} siteWidthClass={"lk"}>
                <LkSidebar/>

                <div className={"lk-content"}>
                    <Route exact path={"/lk/user/edit"}>

                        <DataProvider loadingText={"Загружаем данные"} authToken={true}
                                      url={`${siteApiHost}auth/login`}
                                      onDataLoaded={this.onUserDataLoaded}>
                            {this.state.userData ? <LkEditProfile user={this.state.userData}/> :
                                <Fragment/>}
                        </DataProvider>
                    </Route>

                    <Route exact path={"/lk/:orders_type"} component={OrderTypeResolver}/>
                </div>
            </Page>
        )
    }
}