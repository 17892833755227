import React from "react";

export default class NotyBlock extends React.Component {
  render() {
    return(
      <div className="noty-block">
        <div>
            <div className="noty-h2">Компенсации</div>
            <div className="noty-text">
                Если Пользователь отказывается выполнить рейс на вышеуказанных условиях, он уплачивает
                компенсацию
                за отмену рейса (процент от стоимости чартера в соответствии с п._______. Договора в
                размере:<br/>
                25% после подписания этого подтверждения<br/>
                45%, если уведомление об отмене получено менее чем за 72 часа до вылета<br/>
                60%, если уведомление об отмене получено менее чем за<br/>
                48 часов до вылета <br/>
                100%, если уведомление об отмене получено менее чем за 24 часа до вылета <br/>
                100%, если чартерный рейс уже вылетел с аэропорта согласованного маршрута.
            </div>
        </div>
        <div>
            <div className="noty-h2">Дополнительные условия и положения:</div>
            <div className="noty-text">
                1. Предоставить в авиакомпанию информацию о пассажире (ФИО, № паспорта, пол, дату
                рождения…..) не
                позднее чем за ___________ часа до вылета чартерного рейса по расписанию.<br/>
                2. Прибыть в терминал аэропорта вылета за 30 минут до вылета рейса по расписанию.<br/>
                3. Изменение расписания полета (время местное) зависит от согласования с Авиакомпанией
                (Перевозчиком) при наличии возможности у последней.<br/>
                4. В случае задержки вылета рейса со стороны Пользователя более 3 (трех) часов туда или
                обратно,
                относительно расписания определенного в Заказе, подлежит дополнительному согласованию с
                Перевозчиком. При невозможности выполнить авиаперевозку по новому расписанию, Пользователь
                выплачивает Перевозчику компенсацию, равную полной сумме согласованной цены авиаперевозки, и
                возмещает все расходы Перевозчика, связанные с организацией обеспечения рейса. Максимальное
                время
                ожидания пассажиров при обратном полете – 3 (три) часа, Воздушное судно, вылетает по
                расписанию
                определенному в Заказе +3 часа.<br/>
                5. Пользователь гарантирует согласие пассажиров на использование и обработку их персональных
                данных
                Перевозчиком для предоставления полного и качественного обеспечения чартерной
                перевозки.<br/>
                6. Стоимость чартерной перевозки не включает стоимость противообледенительной обработки
                воздушного
                судна, страхование военных рисков, дополнительную стоимость страхования, сверхурочные часы
                работы
                экипажа, любые дополнительные посадки, изменения расписания/маршрута, стоянку воздушного
                судна более
                чем указано в расписании, продление регламента работы аэропорта (при необходимости),
                дополнительное
                питание, использование спутникового телефона (при наличии) и т.д.<br/>
                7. С учетом конструктивных особенностей пассажирской кабины ВС и багажного отделения (при
                наличии)
                нормы багажа по габаритам__________ и количеству____________.<br/>
                8. Если перевозка не выполняется или прерывается по причинам, не связанным с противоправными
                действиями Перевозчика (включая, но не ограничиваясь, любые случаи форс-мажорных
                обстоятельств, а
                также поломки и неисправности самолета, возникающие в порядке его нормальной эксплуатации),
                Пользователь не имеет права отказываться платить за чартерный рейс, который включает в себя
                подготовку, выполненную часть рейса до момента его прекращения). Также Пользователь
                оплачивает
                расходы, связанные с возвратом воздушного судна с момента прекращения перевозки в базовый
                аэропорт
                (Внуково-3).<br/>
                9. Подтверждение любых согласованных изменений условий перевозки, возникающих после
                подписания
                договора перевозки, является письменное согласование Сторон (SMS, e-mail, факсы и
                т.д.).<br/>
                10. Класс питания __________________.<br/>
                Курение на борту запрещено/разрешено. Запрещено :штраф - 1500 евро.
            </div>
        </div>
        <span className="noty-more" title="Развернуть/Свернуть"
              onClick="this.parentElement.classList.toggle('noty-block-expanded');"></span>
    </div>
  )}
}