import React from "react";
import {Modal} from "../Modals";
import Form from "../Form";
import {ForgotProvider} from "../providers/ForgotProvider";
import {ModalContext} from "../Page";

export default class ModalForgot extends React.Component {
    constructor(props) {
        super(props);
        ModalForgot.contextType = ModalContext
    }

    render() {
        return (
            <Modal title={"Восстановление пароля"}
                   subtitle={"Ссылка для восстановления пароля будет отправлена на ваш email"}
                   type={"forgot"} {...this.props}>

                <Form.Form>
                    <Form.EmailControl error={"Пользователь с таким email не найден"}/>
                    <Form.Submit label={"Отправить"} provider={<ForgotProvider/>}/>
                </Form.Form>

            </Modal>
        )
    }
}