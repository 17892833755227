import React from "react";
import OrdersBlockLgRow from "./OrdersBlockLgRow";
import Logic from "../../../../components/Logic";

export default class OrdersBlockLg extends React.Component {

    render() {
        const {items, orders_type} = this.props;

        return (
            <div className="orders-block orders-block-lg">
                <div className="orders-head">
                    <table>
                        <tr>
                            <th className="order-num">Номер заказа</th>
                            <th className="order-plane">Воздушное судно</th>
                            <th className="order-logo">Авиакомпания</th>
                            <th className="order-airport">Аэропорт вылета</th>
                            <th className="order-date">Дата и время вылета</th>
                            <th className="order-airport">Аэропорт прилета</th>
                            <th className="order-date">Дата и время прилета</th>
                            <th className="order-status">Статус</th>
                        </tr>
                    </table>
                </div>

                {items.map(item => (
                    <div className="orders-body">
                        <table>
                            {item.routes.filter(route => route).map(route => (
                                <OrdersBlockLgRow route={route} order={item} orders_type={orders_type} />
                            ))}
                        </table>
                    </div>
                ))}

                <Logic.Unless item={items}>
                    <h2 className={"empty-result"}>Пусто&hellip;</h2>
                </Logic.Unless>
            </div>
        )
    }
}