import React from "react";
import {ModalContext} from "../Page";
import DataProvider, {siteApiHost} from "../DataProvider";

export class NewPasswordProvider extends React.Component {
    constructor(props) {
        super(props);
        NewPasswordProvider.contextType = ModalContext;
    }

    render() {

        const {new_password_token} = this.context.data;

        return <DataProvider
            method={'PUT'}
            url={`${siteApiHost}auth/new-password`}
            body={{
                new_password: this.props.form.password,
                new_password_token: new_password_token,
            }}
            loadingText={<i>Сохраняем пароль&hellip;</i>}
        />
    }
}