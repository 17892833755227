import React, {Fragment} from "react";
import NotyBlock from "../NotyBlock";
import FlightBlockRowMd from "./FlightBlockRowMd";
import FlightBlockRowLg from "./FlightBlockRowLg";
import RouteDecorator from "../../../../components/RouteDecorator";

export default class FlightBlock extends React.Component {
  render() {
    const {card, size} = this.props;

    return (
      <Fragment>
        {card.routes.map((route, index) => (
            <Fragment>
                {
                    route ?
                        <Fragment>
                            <RouteDecorator route = {route}>
                                {size == "Md" ? <FlightBlockRowMd index={index} {...this.props}/> :
                                    <FlightBlockRowLg index={index} {...this.props}/> }
                            </RouteDecorator>
                            <NotyBlock />
                        </Fragment> :
                        <Fragment />
                }
            </Fragment>
        ))}
      </Fragment>
    )
  }
}