import React, {Fragment} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import LkPage from "./pages/LkPage";
import OrderPage from "./pages/OrderPage";

import "./css/App.css";

export default function App() {
    return (
        <Fragment>
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/"}>
                        <IndexPage/>
                    </Route>

                    <Route exact path={"/search/:sid"} component={IndexPage}/>

                    <Route path={"/lk"}>
                        <LkPage/>
                    </Route>

                    <Route path={"/logout"}>
                        <Redirect push to={"/"}/>
                    </Route>

                    <Route path={"/search/:sid/cards/:cid"} component={OrderPage}/>
                    <Route path={"/orders/:oid"} component={OrderPage}/>
                </Switch>
            </BrowserRouter>
        </Fragment>
    );
}
