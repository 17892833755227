import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {ModalContext} from "./Page";
import {accessToken} from "./Auth";

class LoginLogout extends React.Component {
    constructor(props) {
        super(props);
        LoginLogout.contextType = ModalContext
    }

    render() {
        const {showLoginModal, onLogout} = this.context;

        if (!accessToken()) {
            return <a className="item-login"
                      onClick={showLoginModal}>Войти</a>
        } else {
            return (
                <Fragment>
                    <Link to="/lk/user/edit">ЛК</Link>
                    <Link className="item-logout"
                          onClick={onLogout}>Выйти</Link>
                </Fragment>
            )
        }
    }
}

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        Header.contextType = ModalContext
    }

    render() {
        console.log(this.context);

        return (
            <header className={this.props.className}>
                <div className="site-width">
                    <div className="left">
                        <span className="logo"><Link to={"/"}><img
                            src="/img/logo.svg" alt="NAJET"/></Link></span>
                    </div>
                    <div className="right">
                        <a href="#" className="nav-opener" title="Открыть меню"
                           onClick="this.classList.toggle('nav-active'); return false;"></a>
                        <nav>
                            <div className="nav-close"
                                 title="Закрыть меню"></div>
                            <div className="item"><Link to="/hgb">О
                                компании</Link></div>
                            <div className="item"><Link to="/how-it-works">Как
                                это работает</Link></div>
                            <div className="item"><Link to="/rules">Правила
                                работы</Link></div>
                            <div className="item"><Link
                                to="/partners">Партнеры</Link></div>
                            <div className="item"><Link
                                to="/contacts">Контакты</Link></div>
                        </nav>
                        <div className="lang">EN</div>
                        <nav>
                            <div className="item">
                                <LoginLogout {...this.props} />
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}
