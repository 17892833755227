import React from "react";
import DataProvider, {siteApiHost} from "../DataProvider";
import {ModalContext} from "../Page";

export class ForgotProvider extends React.Component {
    constructor(props) {
        super(props);
        ForgotProvider.contextType = ModalContext;
    }

    onDataLoaded = (data) => {
        this.context.showConfirmModal();
    };

    render() {
        return <DataProvider
            method={'POST'}
            authToken={true}
            url={`${siteApiHost}auth/new-password`}
            onDataLoaded={this.onDataLoaded}
        />
    }
}