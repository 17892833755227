import React, {Fragment} from "react";
import Media from "react-media";
import OrdersBlockSm from "./ordersBlock/OrdersBlockSm";
import OrdersBlockLg from "./ordersBlock/OrdersBlockLg";

export default class OrdersBlock extends React.Component {
    render() {
        const {items} = this.props;

        return (
            <Media queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px) and (max-width: 1199px)",
                large: "(min-width: 1200px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.small && <OrdersBlockSm {...this.props} />}
                        {matches.medium && <OrdersBlockLg {...this.props} />}
                        {matches.large && <OrdersBlockLg {...this.props} />}
                    </Fragment>
                )}
            </Media>
        )
    }
}