import React from "react";

export default class ShareBlock extends React.Component {
    render() {
        return (
            <div className="share-block">
                <a href="#">Отправить все предложения</a>
            </div>
        )
    }
}