import React from "react";
import Header from "./Header";
import Modals from "./Modals";
import {accessToken, logout, setAccessToken} from "./Auth";
import Logic from "./Logic";

export const ModalContext = React.createContext({});

export default class Page extends React.Component {
    state = {
        showModal: "",
        card: null,
        order: null,
        authToken: null,
        newPasswordToken: null,
        data: null,
    };

    onLogout = () => {
        logout();
        this.setState({authToken: null});
    };

    onLoginSuccess = (data) => {
        if (data.hasOwnProperty("access_token")) {
            setAccessToken(data);
            this.setState({authToken: data.access_token, showModal: ""});
        }
    };

    _showModal = (event, name) => {
        try {
            event.preventDefault();
        } catch (e) {
        }
        this.setState({showModal: name});
    };

    showLoginModal = (event) => {
        this._showModal(event, "login")
    };

    showRegModal = (event) => {
        this._showModal(event, "reg")
    };

    showForgotModal = (event) => {
        this._showModal(event, "forgot")
    };

    showPayModal = (event, order) => {
        this.setState({order: order});
        this._showModal(event, "pay");
    };

    showConfirmModal = (event) => {
        this._showModal(event, "confirm");
    };

    showNewPasswordModal = (event) => {
        this._showModal(event, "newpassword");
    };

    onModalClose = () => {
        this.setState({showModal: ""});
    };

    setData = (data) => {
        this.setState({data: data});
    };

    componentDidMount() {
        this.setState({authToken: accessToken()});
    }

    render() {
        const headerClass = "common_page";
        const mainDivClass = `main ${this.props.mainClass}`;
        const siteWidthClass = `site-width ${this.props.siteWidthClass || ""}`;

        const {card, authToken, order, showModal, data} = this.state;
        return (
            <ModalContext.Provider value={{
                showForgotModal: this.showForgotModal,
                showLoginModal: this.showLoginModal,
                onModalClose: this.onModalClose,
                showRegModal: this.showRegModal,
                showPayModal: this.showPayModal,
                showConfirmModal: this.showConfirmModal,
                showNewPasswordModal: this.showNewPasswordModal,
                onLogout: this.onLogout,
                onLoginSuccess: this.onLoginSuccess,
                setData: this.setData,
                card: card,
                order: order,
                show: showModal,
                data: data,

            }}>
                <Logic.If item={this.props.showHeader}>
                    <Header authToken={authToken}
                            className={headerClass}/>
                </Logic.If>

                <div className={mainDivClass}>
                    <div className={siteWidthClass}>
                        <Modals/>
                        {this.props.children}
                    </div>
                </div>
            </ModalContext.Provider>
        )
    }
}