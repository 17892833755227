import React, {Fragment} from "react";
import DataProvider, {siteApiHost} from "../../../components/DataProvider";
import Logic from "../../../components/Logic";
import Form from "../../../components/Form";
import OrderPayProvider from "../../../components/providers/OrderPayProvider";
import OrderCancelProvider
    from "../../../components/providers/OrderCancelProvider";
import OrderBookingProvider
    from "../../../components/providers/OrderBookingProvider";

export default class OrderOrderBlockForm extends React.Component {
    state = {
        submitDisabled: true,
        booking: false,
        payDisabled: true,
        cancelOrder: false,
        order: null,
    };

    openPayModal = (event) => {
        this.props.showPayModal(event, this.state.order || this.props.order);
    };

    cancelOrder = () => {
        this.setState({cancelOrder: true});
    };

    setPayDisabled = () => {
        this.setState({payDisabled: !this.state.payDisabled});
    };

    setSubmitDisabled = () => {
        this.setState({submitDisabled: !this.state.submitDisabled});
    };

    onBookingComplete = (order) => {
        this.setState({
            order: order.order,
            booking: false,
            submitDisabled: true
        });
        this.props.onOrderCreated(this.state.order);
    };

    onBooking = (event) => {
        event.preventDefault();
        this.setState({booking: true});
    };

    render() {
        const {card, order} = this.props;

        return (
            <Fragment>
                <Logic.If item={this.state.booking}>
                    <DataProvider
                        loadingText={<h1 className={"empty-result"}>Бронируем
                            для вас&hellip;</h1>}
                        body={{
                            card_id: this.props.card.id
                        }}
                        method="POST"
                        authToken={true}
                        onDataLoaded={this.onBookingComplete}
                        url={`${siteApiHost}orders`}>

                        <Logic.If item={this.state.order}>
                            <OrderOrderBlockForm order={this.state.order}
                                                 card={card}/>
                        </Logic.If>

                    </DataProvider>
                </Logic.If>

                <Logic.Unless item={order}>
                    <Form.Form>
                        <Form.CheckboxControl
                            name={"type"}
                            label={"Я ознакомился и согласен со всеми правилами и условиями"}/>

                        <Form.Submit
                            className={"form-control"}
                            label={"Забронировать"}
                            provider={<OrderBookingProvider card={card}/>}/>

                        <div>Забронированный рейс вы сможете оплатить или
                            отправить на согласование
                        </div>
                    </Form.Form>
                </Logic.Unless>


                <Logic.If item={order && order.order_status === "booked"}>
                    <Form.Form>
                        <Form.CheckboxControl
                            name={"type"}
                            label={"Я ознакомился и согласен со всеми правилами и условиями"}/>

                        <Form.Submit
                            label={"Оплатить"}
                            provider={<OrderPayProvider/>}/>

                        <Form.Submit
                            label={"Отменить бронь"}
                            provider={<OrderCancelProvider/>}/>

                        <div className="form-links">
                            <a href="#" className="form-links-share">Отправить
                                предложение</a>
                            <a href="#"
                               className="form-links-print">Распечатать</a>
                        </div>
                    </Form.Form>
                </Logic.If>
            </Fragment>
        );
    }
}